import { useEffect, useState } from "react";
import "./footer.css";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileURL";
import { removeHTML } from "../../utils/util";
import { Link } from "react-router-dom";
import { BsFacebook, BsInstagram, BsLinkedin } from "react-icons/bs";

function Footer() {
  const [siteSettings, setSiteSettings] = useState([]);
  const [footerCMS, setFooterCMS] = useState([]);
  const [isHoveredFacebook, setIsHoveredFaceBook] = useState(false);
  const [isHoveredInsta, setIsHoveredInsta] = useState(false);
  const [isHoveredLinkedIn, setIsHoveredLinkedIn] = useState(false);

  const fetchData = async () => {
    const res = await makeRequest(`/site-settings`);
    if (res) {
      setSiteSettings(res?.data?.data);
    }
  };

  const fetchCMS = async () => {
    const response = await makeRequest(`/cms/footer`);
    if (response) {
      setFooterCMS(response?.data?.data?.childPages);
    }
  };

  useEffect(() => {
    fetchData();
    fetchCMS();
  }, []);

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <footer className="footer">
      <div className="container footer-wrapper">
        <div className="content-main">
          <div className="content-left">
            <Link to="/">
              <img
                src={
                  siteSettings.footerLogo
                    ? getFileUrl(siteSettings.footerLogo)
                    : "../assets/images/logo2.png"
                }
                alt="logo"
              />
            </Link>
            <h1 className="heading">{footerCMS[0] && footerCMS[0].title}</h1>
            <p className="subheading text-justify">
              {footerCMS[0] && removeHTML(footerCMS[0].description)}
            </p>

            <div className="social-media">
              <ul className="d-flex gap-3">
                <a
                  href={siteSettings.facebook}
                  target="_blank"
                  rel="noreferrer"
                  onMouseEnter={() => setIsHoveredFaceBook(true)}
                  onMouseLeave={() => setIsHoveredFaceBook(false)}
                >
                  {/* //   <BsFacebook size={20} color="#0588A6" /> */}

                  <BsFacebook
                    size={20}
                    color={isHoveredFacebook ? "#F08A25" : "#0588A6"}
                  />
                </a>
                <a
                  href={siteSettings.twitter}
                  target="_blank"
                  rel="noreferrer"
                  onMouseEnter={() => setIsHoveredInsta(true)}
                  onMouseLeave={() => setIsHoveredInsta(false)}
                >
                  <BsInstagram
                    size={20}
                    color={isHoveredInsta ? "#F08A25" : "#0588A6"}
                  />
                </a>
                <a
                  href={siteSettings.linkedIn}
                  target="_blank"
                  rel="noreferrer"
                  onMouseEnter={() => setIsHoveredLinkedIn(true)}
                  onMouseLeave={() => setIsHoveredLinkedIn(false)}
                >
                  <BsLinkedin
                    color={isHoveredLinkedIn ? "#F08A25" : "#0588A6"}
                    size={20}
                  />
                </a>
              </ul>
            </div>
          </div>
          <div className="content-center">
            <h2>MORE OF INTOPROS</h2>
            <ul className="menu">
              <li className="menu-item">
                <Link className="menu-link" to={"/about"}>
                  About us
                </Link>
              </li>
              <li className="menu-item">
                <Link className="menu-link" to={"/careers"}>
                  Careers
                </Link>
              </li>
              <li className="menu-item">
                <Link className="menu-link" to={"/blogs"}>
                  Blogs
                </Link>
              </li>
              <li className="menu-item">
                <Link className="menu-link" to={"/works"}>
                  Works
                </Link>
              </li>
              <li className="menu-item">
                <Link className="menu-link" to={"/tools-and-technologies"}>
                  Tools &amp; Technologies
                </Link>
              </li>
              <li className="menu-item">
                <Link className="menu-link" to={"/privacy-policy"}>
                  Privacy Policy 
                </Link>
              </li>
            </ul>
          </div>
          <div className="content-right">
            <h2>GET IN TOUCH</h2>
            <div>
              <h3 className="name">{siteSettings.officeName}</h3>
              <ul className="info">
              <li><a href={`mailto:${siteSettings.email}`}>{siteSettings.email}</a></li>
              <li><a href={`tel:${siteSettings.phone}`}>{siteSettings.phone}</a></li>
                <li>{siteSettings.address}</li>
              </ul>
            </div>
            <div>
              <h2 className="name">{siteSettings.officeName2}</h2>
              <ul className="info">
                <li><a href={`mailto:${siteSettings.email}`}>{siteSettings.email2}</a></li>
                <li><a href={`tel:${siteSettings.phone}`}>{siteSettings.phone2}</a></li>
                <li>{siteSettings.address2}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        {/* <h1>{footerCMS[1] && removeHTML(footerCMS[1].description)}</h1> */}
        <h1>
          {footerCMS[1] &&
            removeHTML(footerCMS[1].description).replace(
              "2023",
              getCurrentYear()
            )}
        </h1>
      </div>
    </footer>
  );
}

export default Footer;
