import "./about.css";
import { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import makeRequest from "../../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getFileUrl from "../../utils/getFileURL";
import LoadingSpinner from "../../LoadingSpinner";
import { ParseHTML, removeHTML } from "../../utils/util";
import ReactQuill from "react-quill";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

import "../../css/grid.min.css";
import "../../css/pagination.min.css";
import InquirySection from "../Home/InquirySection";

function About() {
  const [name, setName] = useState("");
  const [tech, setTech] = useState("");
  const [email, setEmail] = useState("");
  const [budget, setBudget] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [validated, setValidated] = useState(false);
  const [fetchTech, setFetchTech] = useState([]);
  const [aboutCMS, setAboutCMS] = useState([]);
  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTechData = async () => {
    const res = await makeRequest(`/technologies?take=0`);
    if (res) {
      setFetchTech(res.data?.data);
    }

    const response = await makeRequest(`/our-team?take=0`);
    if (response) {
      setTeams(response?.data?.data);
    }
  };

  const fetchCMS = async () => {
    setIsLoading(true);
    const res = await makeRequest(`/cms/about`);
    if (res) {
      setAboutCMS(res.data?.data.childPages);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTechData();
    fetchCMS();
  }, []);

  const handleTechChange = (e) => {
    setTech(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setValidated(true);

      if (validated === true) {
        const data = new FormData();
        data.append("name", name);
        data.append("email", email);
        data.append("budget", budget);
        data.append("tech", tech);
        data.append("message", message);
        data.append("file", file);

        const res = await makeRequest(`/contact-us`, {
          method: "POST",
          data: data,
        });
        if (res) {
          toast.success(res.data?.message);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>About Intopros Technologies Pvt Ltd</title>
        <meta
          name="description"
          content={aboutCMS?.metaDescription ?? "Intopros Technologies Pvt Ltd"}
        />
      </Helmet>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <main>
          {aboutCMS.length > 0 && (
            <section className="about" id="about">
              <div className="container-fluid about-wrapper">
                <div className="container content-main">
                  <div className="col-lg-6 content-left">
                    <h1 className="heading">{aboutCMS[0].title}</h1>
                    <div className="subheading">
                      {aboutCMS[0].description &&
                        ParseHTML(aboutCMS[0].description)}
                    </div>
                  </div>
                  <div className="col-lg-6 background-image">
                    <img
                      className="img-fluid"
                      src={
                        aboutCMS[0].image
                          ? getFileUrl(aboutCMS[0].image)
                          : "assets/images/background.png"
                      }
                      alt="Intopros Technologies"
                    />
                  </div>
                </div>
              </div>
            </section>
          )}
          <section className="container our-team-section">
            <div className="our-team-wrapper">
              <div className="heading d-flex flex-column align-items-center">
                <h2 className="header">Our Team</h2>
                <p className="subheading">
                  Meet the amazing team of Intopros Technologies
                </p>
              </div>
              <div className="content-main-about">
                {/* <Swiper
                  slidesPerView={1}
                  grid={{
                    rows: 2,
                    fill: "row",
                  }}
                  breakpoints={{
                    // when window width is >= 768px
                    768: {
                      slidesPerView: 2,
                    },
                    // when window width is >= 992px
                    992: {
                      slidesPerView: 3,
                    },
                    // when window width is >= 1200px
                    1200: {
                      slidesPerView: 4,
                    },
                  }}
                  spaceBetween={120}
                  // pagination={{
                  //   clickable: true,
                  // }}
                  modules={[Grid, Pagination, Navigation]}
                  className="mySwiper px-5"
                >
                  {teams?.map((team, index) => (
                    <SwiperSlide key={index}>
                      <Card className="align-items-center">
                        <Card.Img
                          variant="top"
                          src={
                            team.image
                              ? getFileUrl(team?.image)
                              : "assets/images/placeholder.png"
                          }
                          style={{
                            borderRadius: "50%",
                            width: "128px",
                            height: "128px",
                            // height: "320px",
                            // width: "285px",
                            objectFit: "cover",
                            zIndex: "10",
                            // filter: "grayscale(10)",
                          }}
                        />
                        <Card.Body className="card-body">
                          <Card.Title className="mt-5">{team.name}</Card.Title>
                          <Card.Text>{removeHTML(team.designation)}</Card.Text>
                        </Card.Body>
                      </Card>
                    </SwiperSlide>
                  ))}
                </Swiper> */}
                <div className="team-list">
                  {teams?.map((team, index) => (
                    // <SwiperSlide key={index}>
                    <div key={index} className="team-member">
                      <img
                        className="teams-image"
                        variant="top"
                        src={
                          team.image
                            ? getFileUrl(team?.image)
                            : "assets/images/placeholder.png"
                        }
                      />
                      <div className="card-body">
                        <div className="title-name">{team.name}</div>
                        <p className="subtitle-name">
                          {removeHTML(team.designation)}
                        </p>
                      </div>
                    </div>
                    // {/* </SwiperSlide> */}
                  ))}
                </div>
              </div>
            </div>
          </section>
          <section className="image-section mt-0">
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <div className="container image-section-wrapper">
                <div className="subheading">
                  {/* <div>
                    <ReactQuill
                      value={aboutCMS[4]?.description}
                      readOnly={true}
                      theme="bubble"
                    />
                  </div> */}
                </div>
              </div>
            )}
          </section>
          {/* <section className="inquiry" id="inquiry">
            <div className="container-fluid inquiry-wrapper">
              <div className="row">
                <div className="quote-section" style={{ position: "relative" }}>
                  <div className="col-lg-12 quote-image">
                    <img
                      className="col-sm-12"
                      src="../assets/images/quote-bg.png"
                      alt=""
                    ></img>
                    <div className="col-md-4 col-xs-7 quote-text">
                      <p className="quote text-white">
                        To me, ideas are worth nothing unless executed. They are
                        just a multiplier. Execution is worth millions.
                      </p>
                      <small className="person text-white">Steve Jobs</small>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-8 col-xs-4 form">
                    <Form
                      className="inquiry-form"
                      noValidate
                      validated={validated}
                    >
                      <div className="info">
                        <div className="title">
                          <h2 className="heading">
                            Talk to us and get your project moving!
                          </h2>
                        </div>
                        <Form.Group className="mb-3" controlId="formBasicTech">
                          <Form.Select
                            aria-label="Select Technology"
                            required
                            name="tech"
                            id="tech"
                            onChange={handleTechChange}
                          >
                            <option selected>Select Technology</option>
                            {fetchTech.map((tech, index) => {
                              return (
                                <option key={index} value={tech.title}>
                                  {tech.title}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please select a tech.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Control
                            required
                            placeholder="Full Name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            style={{
                              border: "none",
                              "border-bottom": "1px solid #686868",
                            }}
                          />

                          <Form.Control.Feedback type="invalid">
                            Please enter your name.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            required
                            placeholder="Email Address"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{
                              border: "none",
                              "border-bottom": "1px solid #686868",
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter your email.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicBudget"
                        >
                          <Form.Control
                            required
                            placeholder="My Budget is.."
                            type="text"
                            value={budget}
                            onChange={(e) => setBudget(e.target.value)}
                            style={{
                              border: "none",
                              "border-bottom": "1px solid #686868",
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide your budget.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicMessage"
                        >
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={message}
                            placeholder="My requirements are"
                            onChange={(e) => setMessage(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3 upload-btn-wrapper"
                          controlId="fileInput"
                        >
                          <button class="btn-upload">Upload Attachments</button>
                          <Form.Control
                            type="file"
                            name="file"
                            style={{ height: "auto" }}
                            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            onChange={(e) => setFile(e.target.files[0])}
                          />
                        </Form.Group>
                        <Form.Label>
                          Note: Please upload pdf,doc or docx files only!
                        </Form.Label>
                        <div className="content-bottom">
                          <button
                            className="btn-continue"
                            type="submit"
                            href="#"
                            onClick={handleSubmit}
                          >
                            Request a callback
                          </button>
                          <ToastContainer />
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <InquirySection />
        </main>
      )}
    </>
  );
}

export default About;
