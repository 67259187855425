import { useEffect, useState } from "react";
import makeRequest from "../../utils/api";
import { removeHTML } from "../../utils/util";
import getFileUrl from "../../utils/getFileURL";
import LoadingSpinner from "../../LoadingSpinner";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import ResultPage from "../Home/ResultPage";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import { Dropdown } from "react-bootstrap";

function Works() {
  const [isOpen, setIsOpen] = useState(false);
  const [works, setWorks] = useState([]);
  const [filteredWorks, setFilteredWorks] = useState([]);
  const [services, setServices] = useState([]);
  const [cmsWorks, setCMSWorks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [resultCMS, setResultCMS] = useState([]);
  const [selectedService, setSelectedService] = useState("All");

  const location = useLocation();

  const fetchWorks = async (value) => {
    const res = await makeRequest(`/works?take=0&status=all`);
    if (res) {
      setWorks(res?.data?.data);
      if (value) {
        const filter = res?.data?.data.filter((work) =>
          work?.serviceTypes?.split(",").includes(value)
        );
        setFilteredWorks(filter);
      } else {
        setFilteredWorks(res?.data?.data);
      }
    }
  };

  const fetchServices = async () => {
    const response = await makeRequest(`/services?take=0`);
    if (response) {
      setServices(response?.data?.data);
    }
  };

  const fetchCMS = async () => {
    setIsLoading(true);
    const res = await makeRequest(`/cms/works`);
    if (res) {
      setCMSWorks(res.data?.data?.childPages[0]);
      setIsLoading(false);
    }
  };
  const fetchCMSresult = async () => {
    const resp = await makeRequest(`/cms/result-section`);
    if (resp) {
      setResultCMS(resp.data?.data.childPages);
    }
  };
  useEffect(() => {
    fetchServices();
    fetchWorks(location.state);
    fetchCMS();
  }, [location.state]);

  useEffect(() => {
    fetchCMSresult();
  }, []);

  const handleChange = (value) => {
    setSelectedService(value);
    if (value === "All") {
      fetchWorks(location.state);
    }
    const filter = works.filter((work) =>
      work?.serviceTypes?.split(",").includes(value)
    );
    setFilteredWorks(filter);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Helmet>
        <title>Intopros Technologies Projects</title>
        <meta
          name="description"
          content={cmsWorks?.metaDescription ?? "Intopros Technologies Pvt Ltd"}
        />
      </Helmet>
      <main>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <section className="works" id="works">
              <div className="container works-wrapper d-flex flex-column">
                <div className="content-header d-flex flex-column align-items-center">
                  <h1 className="heading">{cmsWorks?.title}</h1>
                  <p className="subheading">
                    {removeHTML(cmsWorks?.description ?? "")}
                  </p>
                </div>
                <div className="d-flex justify-content-end mb-2 ">
                  <Dropdown show={isOpen} onToggle={toggleDropdown}>
                    <Dropdown.Toggle
                      variant="light"
                      className="dropdown"
                      onChange={(e) => handleChange(e.target.value)}
                    >
                      <div className="allselect">{selectedService}</div>
                      {isOpen ? (
                        <RiArrowDropUpLine className="left-right-icon" />
                      ) : (
                        <RiArrowDropDownLine className="left-right-icon" />
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{ cursor: "pointer" }}
                      className="dropdownmenu"
                    >
                      <Dropdown.Item
                        onClick={() => handleChange("All")}
                        style={{ padding: "5px 10px", color: "black" }}
                        className="dropdown-item-services"
                      >
                        All
                      </Dropdown.Item>
                      {services?.map((service) => (
                        <Dropdown.Item
                          key={service.id}
                          active={service.title === location.state}
                          onClick={() => handleChange(service.title)}
                          style={{ padding: "5px 10px", color: "black" }}
                        >
                          {service.title}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                {filteredWorks?.length > 0 ? (
                  <div
                    className={`grid-container ${
                      filteredWorks.length === 1
                        ? "single-column"
                        : "two-columns"
                    }`}
                  >
                    {filteredWorks?.map((work, index) => {
                      return (
                        <div
                          key={index}
                          className="work-card cards justify-content-between"
                        >
                          <div className="d-flex gap-4 works-section">
                            <img
                              className="logo img-fluid"
                              src={
                                work.logo ? getFileUrl(work?.logo) : "/logo.png"
                              }
                              height={100}
                              width={100}
                              style={{
                                objectFit: "contain",
                              }}
                              alt={work.title}
                            />
                            <div className="work-details">
                              <h1 className="title">{work?.title}</h1>
                              <p className=" text-start work-para">
                                {removeHTML(work?.shortDescription).substring(
                                  0,
                                  100
                                )}
                                ...
                              </p>
                            </div>
                          </div>
                          <div className=" work-section-wrap">
                            <Link to={`/works/${work?.slug}`}>
                              <button className="btn-more">See More</button>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p className="d-flex justify-content-center mt-5">
                    No works found
                  </p>
                )}
              </div>
            </section>
            {resultCMS.length > 0 && (
              <section className="result" id="result">
                <div className="container-fluid result-wrapper-section">
                  <div className="container">
                    <h1 className="heading mb-1" style={{ fontWeight: "600" }}>
                      {resultCMS[0].title}
                    </h1>
                    <div className="d-flex justify-content-center">
                      <p
                        className="subheading col-lg-6  text-center "
                        style={{ fontWeight: "400" }}
                      >
                        {removeHTML(resultCMS[0].description)}
                      </p>
                    </div>
                    <div className="content">
                      <ul className="numbers-list">
                        {resultCMS
                          .filter((result) => result !== resultCMS[0])
                          .map((result, index) => {
                            return (
                              <li
                                key={index}
                                className="menu-list d-flex flex-column numbers-listItem"
                              >
                                <ResultPage result={result}></ResultPage>

                                <span>{removeHTML(result.description)}</span>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </>
        )}
      </main>
    </>
  );
}

export default Works;
