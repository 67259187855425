import "../Blog-Detail/blog-detail.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileURL";
import { ParseHTML } from "../../utils/util";
import { Helmet } from "react-helmet";

function FrameworkDetail() {
  const { id } = useParams();
  const [framework, setFramework] = useState([]);

  useEffect(() => {
    const fetchFramework = async () => {
      const res = await makeRequest(`/technologies/frameworks/${id}`);
      if (res) {
        setFramework(res?.data?.data);
      }
    };
    fetchFramework();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>{framework.title}</title>
        <meta name="description" content={framework.description} />
      </Helmet>
      <main>
        <section className="blog-detail">
          <div className="container blog-detail-wrapper">
            <div className="content">
              <div className="blog-image">
                <img
                  src={
                    framework.image ? getFileUrl(framework.image) : "/logo.png"
                  }
                  className="img-responsive"
                  style={{ height: "100px" }}
                  alt=""
                />
              </div>
              <div className="heading">
                <h1>{framework.title}</h1>
              </div>
              <div className="subheading">
                {framework.description && ParseHTML(framework.description)}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default FrameworkDetail;
