import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "../../LoadingSpinner";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileURL";
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeHTML } from "../../utils/util";
import { Helmet } from "react-helmet";
import ReactQuill from "react-quill";
import { AiOutlineLink } from "react-icons/ai";
import ResultPage from "../Home/ResultPage";
import "./works.css";
import InquirySection from "../Home/InquirySection";

function WorkDetail() {
  const { id } = useParams();

  const [workDetail, setWorkDetail] = useState([]);
  const [fetchTech, setFetchTech] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cmsResult, setCmsResult] = useState([]);
  const [resultCMS, setResultCMS] = useState([]);
  const [name, setName] = useState("");
  const [tech, setTech] = useState("");
  const [email, setEmail] = useState("");
  const [budget, setBudget] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [validated, setValidated] = useState(false);
  const [technologies, setTechnologies] = useState([]);

  const fetchTechs = async () => {
    const res = await makeRequest(`/technologies?take=0`);
    if (res) {
      setTechnologies(res.data?.data);
    }
  };

  const fetchCMS = async () => {
    const response = await makeRequest(`/cms/result-section`);
    if (response) {
      setCmsResult(response.data?.data.childPages);
    }
  };

  const fetchCMSresult = async () => {
    const resp = await makeRequest(`/cms/result-section`);
    if (resp) {
      setResultCMS(resp.data?.data.childPages);
    }
  };

  useEffect(() => {
    fetchCMSresult();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await makeRequest(`/works/${id}`);
      if (res) {
        setWorkDetail(res.data?.data);
        setIsLoading(false);
      }

      setIsLoading(true);
      const response = await makeRequest(`/technologies?take=0`);
      if (response) {
        setFetchTech(response.data?.data);
        setIsLoading(false);
      }
    };
    fetchData();
    fetchCMS();
    fetchTechs();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setValidated(true);

      if (validated === true) {
        const data = new FormData();
        data.append("name", name);
        data.append("email", email);
        data.append("budget", budget);
        data.append("tech", tech);
        data.append("message", message);
        data.append("file", file);

        const res = await makeRequest(`/contact-us`, {
          method: "POST",
          data: data,
        });
        if (res) {
          toast.success(res.data?.message);
          setTimeout(() => {
            window.location.href = `/works/${id}`;
          }, 3000);
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{workDetail?.title}</title>
        <meta
          name="description"
          content={`${workDetail?.title}-Intopros Technologies Pvt Ltd`}
        />
      </Helmet>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <main>
          <section
            className="service-details"
            id="service-details"
            style={{ marginTop: "0" }}
          >
            <div className="container-fluid service-details-wrapper">
              <div className="container work-container-wrapper">
                <div className="content-header">
                  <div
                    className="heading-content text-center"
                    style={{ marginTop: "10px" }}
                  >
                    {workDetail.goTo ? (
                      <a
                        href={workDetail.goTo}
                        target="_blank"
                        rel="noreferrer"
                        className=" works-details-parent"
                      >
                        <h1 className="heading icon-align-details">
                          {workDetail.title}
                          <AiOutlineLink
                            size={25}
                            className="icon-work-details"
                            color="#ffffff"
                            style={{
                              backgroundColor: "#264C7D",
                              borderRadius: "100%",
                              position: "relative",
                              top: 0,
                            }}
                          />
                        </h1>
                      </a>
                    ) : (
                      <div className=" ">
                        <h1 className="heading">{workDetail.title}</h1>
                      </div>
                    )}

                    <p className="subheading " style={{ textAlign: "center" }}>
                      {removeHTML(workDetail.shortDescription)}
                    </p>
                  </div>
                </div>
                <div className="text-center">
                  <img
                    src={
                      workDetail?.banner
                        ? getFileUrl(workDetail?.banner)
                        : "/placeholder.png"
                    }
                    className="img-fluid"
                    height={415}
                    alt={workDetail.title}
                  />
                </div>
              </div>
              <div className="container">
                <div className="content-center text-center">
                  <div className="content center-align">
                    <div className="subheading work-subheading">
                      <ReactQuill
                        value={workDetail?.description}
                        theme="bubble"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {technologies.filter(
            (tech) => workDetail.tags && workDetail.tags.includes(tech.title)
          ).length && (
            <section className="tools-and-technologies mt-5">
              <div className="container tools-and-technologies-wrapper pt-0">
                <div className="d-flex flex-column align-items-center">
                  <h1 className="heading">What we used</h1>
                  <p
                    className="subheading what-we-used"
                    style={{ marginBottom: "48px", textAlign: "center" }}
                  >
                    Some of the tools and technologies we used
                  </p>
                </div>
                <div className="grid-container technologi-container">
                  {technologies
                    .filter(
                      (tech) =>
                        workDetail.tags && workDetail.tags.includes(tech.title)
                    )
                    .map((tech, index) => {
                      return (
                        <div key={index} className="card">
                          <img
                            className="logo"
                            src={
                              tech.image ? getFileUrl(tech.image) : "/logo.png"
                            }
                            alt={tech.title}
                          />
                          <div className="technology-details">
                            <h1>{tech.title}</h1>
                            <p className="subheading">
                              {removeHTML(tech.shortDescription)}
                            </p>
                            <div className="btn-group">
                              <Link to={`/services/${tech.slug}`}>
                                <button className="btn-more">Read More</button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </section>
          )}
          <InquirySection />

          <section className="service-result" id="service-result">
            {/* {cmsResult.length > 1 && (
              <div className="container service-result-wrapper">
                <h1 className="heading">{cmsResult[0].title}</h1>
                <p className="subheading col-md-6">
                  {removeHTML(cmsResult[0].description)}
                </p>
                <div className="content">
                  <ul className="menu">
                    {cmsResult
                      .filter((data) => data !== cmsResult[0])
                      .map((data, index) => {
                        return (
                          <li
                            key={index}
                            className="menu-list d-flex flex-column align-items-center"
                          >
                            <h1>{data.title}</h1>
                            <span>{removeHTML(data.description)}</span>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="content-bottom">
                  <Link to={`/contact-us`}>
                    <button className="btn-contact">Contact Us</button>
                  </Link>
                </div>
              </div>
            )} */}

            {resultCMS.length > 0 && (
              <section className="result" id="result">
                <div className="container-fluid result-wrapper-section-work">
                  <div className="container">
                    <h1 className="heading mb-1" style={{ fontWeight: "600" }}>
                      {resultCMS[0].title}
                    </h1>
                    <div className="d-flex justify-content-center">
                      <p
                        className="subheading col-lg-6  text-center "
                        style={{ fontWeight: "400" }}
                      >
                        {removeHTML(resultCMS[0].description)}
                      </p>
                    </div>
                    <div className="content">
                      <ul className="numbers-list-section">
                        {resultCMS
                          .filter((result) => result !== resultCMS[0])
                          .map((result, index) => {
                            return (
                              <li
                                key={index}
                                className="menu-list d-flex flex-column numbers-listItem"
                              >
                                <ResultPage result={result}></ResultPage>

                                <span>{removeHTML(result.description)}</span>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                  <div className="contact-result">
                    <button>Contact Us</button>
                  </div>
                </div>
              </section>
            )}
          </section>
        </main>
      )}
    </>
  );
}

export default WorkDetail;
