import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../LoadingSpinner";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileURL";
import { removeHTML } from "../../utils/util";

function ToolsAndTechnologies() {
  const [technologies, setTechnologies] = useState([]);
  const [technologiesCMS, setTechnologiesCMS] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTechnologies = async () => {
    setIsLoading(true);
    const res = await makeRequest(`/technologies?take=0`);
    if (res) {
      setTechnologies(res?.data?.data);
      setIsLoading(false);
    }
  };

  const fetchCMS = async () => {
    const res = await makeRequest(`/cms/tools-and-technologies`);
    if (res) {
      setTechnologiesCMS(res?.data?.data);
    }
  };

  useEffect(() => {
    fetchTechnologies();
    fetchCMS();
  }, []);

  return (
    <>
      <Helmet>
        <title>Tools & Technologies</title>
        <meta
          name="description"
          content={
            technologiesCMS?.metaDescription ?? "Intopros Technologies Pvt Ltd"
          }
        />
      </Helmet>
      <main>
        <section className="tools-and-technologies">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="container tools-and-technologies-wrapper">
              <div className="content-header ">
                <h1 className="heading">{technologiesCMS?.title}</h1>

                <p className="subheading">
                  {removeHTML(technologiesCMS?.description ?? "")}
                </p>
              </div>
              <div className="grid-container">
                {technologies?.map((tech, index) => {
                  return (
                    <div key={index} className="card">
                      <img
                        className="logo"
                        src={getFileUrl(tech?.image)}
                        alt=""
                      />
                      <div className="technology-details">
                        <h1>{tech?.title}</h1>
                        <p className="subheading">
                     
                          {removeHTML(tech?.shortDescription).substring(0,
                          100)} ...
                        </p>
                        <div className="btn-group">
                          <Link to={`/services/${tech?.slug}`}>
                            <button className="btn-more">Read More</button>
                          </Link>
                          <Link to={`/contact-us/${tech?.slug}`}>
                            <button className="btn-start start-teg-btn">
                              Start your project
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </section>
      </main>
    </>
  );
}

export default ToolsAndTechnologies;
