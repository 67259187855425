import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./header.css";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileURL";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross1 } from "react-icons/rx";
import { RiArrowDropDownLine, RiCloseLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import NavLogo from "./NavLogo";

function Header() {
  const [siteSettings, setSiteSettings] = useState([]);
  const [services, setServices] = useState([]);
  const [techs, setTechs] = useState([]);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenService, setIsDropdownOpenService] = useState(false);
  const [showAllDropData, setShowAllDropData] = useState(false);

  const location = useLocation();

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  const handleMouseServiceEnter = () => {
    setIsDropdownOpenService(true);
  };

  const handleMouseServiceLeave = () => {
    setIsDropdownOpenService(false);
  };

  const handledropdownService = () => {
    setIsDropdownOpenService(!isDropdownOpenService);
  };

  const handleNavbarToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  useEffect(() => {
    // Reset navbar state to closed when navigating to a new page
    setIsNavbarOpen(false);
  }, [location]);

  const fetchData = async () => {
    const res = await makeRequest(`/site-settings`);
    if (res) {
      setSiteSettings(res?.data?.data);
    }

    const response = await makeRequest(`/services?take=0`);
    if (response) {
      setServices(response?.data?.data);
    }

    const resp = await makeRequest(`/technologies?take=0`);
    if (resp) {
      setTechs(resp?.data?.data);
    }
  };

  useEffect(() => {
    fetchData();
    let nav = document.querySelector(".navigation-wrap");
    window.onscroll = function () {
      if (document.documentElement.scrollTop > 20) {
        nav.classList.add("scroll-on");
      } else {
        nav.classList.remove("scroll-on");
      }
    };

    let navBar = document.querySelectorAll(".nav-link");
    let navCollapse = document.querySelector(".navbar-collapse.collapse");
    navBar.forEach(function (a) {
      a.addEventListener("click", function () {
        navCollapse.classList.remove("show");
      });
    });
  }, []);

  const redirectService = (slug) => {
    window.location.href = `/service-detail/${slug}`;
  };

  const redirectHiring = (slug) => {
    window.location.href = `/hire-a-developer/${slug}`;
  };

  return (
    <header>
      <nav
        className="navbar navbar-expand-xl navigation-wrap"
        id="navigation-wrap"
      >
        <div className="container">
          {/* <Link className="navbar-brand" to="/">
            <img
              src={
                siteSettings.headerLogo
                  ? getFileUrl(siteSettings.headerLogo)
                  : "../assets/images/logo.png"
              }
              className="img-fluid"
              alt="Intopros"
            />
          </Link> */}
          <NavLogo className="image-navbar-logo" />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            // aria-expanded="false"
            // aria-label="Toggle navigation"
            onClick={handleNavbarToggle}
            aria-expanded={isNavbarOpen ? "true" : "false"}
            aria-label={isNavbarOpen ? "Close navigation" : "Open navigation"}
          >
            {isNavbarOpen ? (
              <RxCross1
                size={20}
                className="navbar-toggler-icon"
                style={{ display: "none" }}
              /> // Use your close icon component here
            ) : (
              <RxHamburgerMenu
                size={20}
                className="navbar-toggler-icon"
                style={{ display: "block" }}
              /> // Use your hamburger icon component here
            )}
            {/* <RxHamburgerMenu size={20} className="navbar-toggler-icon" /> */}
          </button>
          <div
            className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`}
            id="navbarSupportedContent"
            // style={{ border: "1px solid red" }}
          >
            {isNavbarOpen && (
              <div className="container responsive-navbar-responsive">
                <NavLogo className="image-navbar-logo" />

                <button
                  className="navbar-toggler close-navbar"
                  type="button"
                  onClick={handleNavbarToggle}
                  aria-label="Close navigation"
                >
                  <RxCross1
                    size={20}
                    className="navbar-close-icon"
                    style={{ color: "#0588A6" }}
                  />
                </button>
              </div>
            )}
            <ul className="navbar-nav ms-auto mb-2 nav-link-ul">
              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link-dropdown dropdown-toggle"
                  to="/hire-a-developer"
                  id="hire-a-developer"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  Hire a Developer
                  {isDropdownOpen ? (
                    <RiArrowDropUpLine style={{ fontSize: "30px" }} />
                  ) : (
                    <RiArrowDropDownLine style={{ fontSize: "30px" }} />
                  )}
                </NavLink>
                <ul
                  className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
          
                  aria-labelledby="hire-a-developer"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {techs.map((tech, index) => {
                    return (
                      (index < 4 || showAllDropData) && (
                        <li key={index}>
                          <Link
                            to={`hire-a-developer/${tech.slug}`}
                            className="dropdown-item"
                            onClick={(e) => redirectHiring(tech.slug)}
                            style={{ cursor: "pointer", color: "gray",  }}
                          >
                            {tech.title}
                          </Link>
                        </li>
                      )
                    );
                  })}
                  {techs && techs.length > 3 && (
                    <span
                      onClick={() => setShowAllDropData(!showAllDropData)}
                      style={{
                        cursor: "pointer",
                        color: "#0a3369",
                        fontWeight: "bold",
                        lineHeight: "3",
                        fontSize: "15px",
                      }}
                    >
                      {showAllDropData ? "- View Less" : "+ View More"}
                    </span>
                  )}
                </ul>
              </li>

              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link-dropdown dropdown-toggle"
                  to="/service-detail"
                  id="services"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onMouseEnter={handleMouseServiceEnter}
                  onMouseLeave={handleMouseServiceLeave}
                >
                  Services
                  {isDropdownOpenService ? (
                    <RiArrowDropUpLine style={{ fontSize: "30px" }} />
                  ) : (
                    <RiArrowDropDownLine style={{ fontSize: "30px" }} />
                  )}
                </NavLink>
                <ul
                  className={`dropdown-menu ${
                    isDropdownOpenService ? "show" : ""
                  }`}
                  style={{ color: "gray" }}
                  aria-labelledby="services"
                  onMouseEnter={handleMouseServiceEnter}
                  onMouseLeave={handleMouseServiceLeave}
                >
                  {services.map((service, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`service-detail/${service.slug}`}
                          className="dropdown-item"
                          onClick={(e) => redirectService(service.slug)}
                          style={{ cursor: "pointer", color: "gray" }}
                        >
                          {service.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link" to="/works">
                  Works
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link" to="/tools-and-technologies">
                  Tools & Technologies
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/careers">
                  Careers
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/blogs">
                  Blogs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about">
                  About
                </NavLink>
              </li>
              <li className="center-container">
                <NavLink to={`/contact-us`}>
                  <button className="contact-btn">Request a quote</button>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>

    
  );
}

export default Header;
