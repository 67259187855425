import { useEffect, useState } from "react";
import Quotes from "../Quotes/quotes";
import { Form } from "react-bootstrap";
import makeRequest from "../../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeHTML } from "../../utils/util";
import { Link, useParams } from "react-router-dom";
import getFileUrl from "../../utils/getFileURL";
import Accordion from "../../components/accordion/faqAccordion";
import LoadingSpinner from "../../LoadingSpinner";
import { Helmet } from "react-helmet";
import WorkSection from "../../components/work-section/work-section";
import { BsArrowRight } from "react-icons/bs";

function HireDeveloper() {
  const [name, setName] = useState("");
  const [tech, setTech] = useState("");
  const [email, setEmail] = useState("");
  const [budget, setBudget] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [validated, setValidated] = useState(false);
  const [fetchTech, setFetchTech] = useState([]);
  const [fetchHiringMenu, setHiringMenu] = useState([]);
  const [works, setWorks] = useState([]);
  const [FAQs, setFAQs] = useState([]);
  const [cmsData, setCmsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const numbers = [{ number: 1 }, { number: 2 }, { number: 3 }, { number: 4 }];

  const fetchCMS = async () => {
    const res = await makeRequest(`/cms/why-intopros`);
    if (res) {
      setCmsData(res.data?.data.childPages);
    }
  };

  const fetchFAQs = async () => {
    const res = await makeRequest(`/technologies/faq?take=0`);
    if (res) {
      setFAQs(res.data?.data);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const res = await makeRequest(`/technologies/${id}`);
      if (res) {
        setFetchTech(res.data?.data);
        setTech(res.data?.data.title);
        setIsLoading(false);
      }

      const response = await makeRequest(`/technologies/hiring-menu`);
      if (response) {
        setHiringMenu(response.data?.data);
      }

      const resp = await makeRequest(`/works?status=all`);
      if (resp) {
        setWorks(resp.data?.data);
      }
    };
    fetchData();
    fetchFAQs();
    fetchCMS();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setValidated(true);

      if (validated === true) {
        const data = new FormData();
        data.append("name", name);
        data.append("email", email);
        data.append("budget", budget);
        data.append("tech", tech);
        data.append("message", message);
        data.append("file", file);

        const res = await makeRequest(`/contact-us`, {
          method: "POST",
          data: data,
        });
        if (res) {
          toast.success(res.data?.message);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Hire the best ${fetchTech?.title} professional`}</title>
        <meta
          name="description"
          content={`${fetchTech?.title} professional at Intopros Technologies Pvt Ltd`}
        />
      </Helmet>
      <main>
        <section className="header-section">
          <div className="container-fluid header-section-wrapper">
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <div className=" hiring-header-section">
                <div
                  className="header-section-heading text-center"
                  style={{ marginTop: "22px" }}
                >
                  <img
                    className="img-fluid"
                    src={getFileUrl(fetchTech.image)}
                    width={80}
                    alt={fetchTech.title}
                  />
                  <div className="heading-content hiring-header-section">
                    <div className="heading-description">
                      <h1>
                        Hire the best {fetchTech?.title?.toUpperCase()} Devs
                      </h1>

                      <p className="col-lg-8 subheading">
                        {removeHTML(fetchTech.shortDescription)}
                      </p>
                    </div>
                    <Link to={`/contact-us/${fetchTech.slug}`}>
                      <button className="btn-start-hiring">
                        Hire {fetchTech.title} Developer
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            <div className="hiring-category">
              <div className="mt-5 grid-container">
                {fetchHiringMenu.map((menu, index) => {
                  return (
                    <Link
                      to={`/contact-us/${menu.id}/${id}`}
                      key={index}
                      className="hiring-card"
                    >
                      <div className="header">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <h1 className="heading mb-0">{menu.title}</h1>
                          <BsArrowRight size={20} color="#ffffff" />
                        </div>
                        <p className="subheading">
                          {removeHTML(menu.description)}
                        </p>
                      </div>
                      <div className="card-body px-0">
                        <div className="timing-card">
                          <img
                            height="30px"
                            width="30px"
                            src="../assets/images/clock.png"
                            alt=""
                            className="icon-hiring"
                          />
                          <h1 className="heading  card-title">Working</h1>
                          <p className="subheading card-paragraph">
                            {menu.working}
                          </p>
                        </div>
                        <div className="timing-card">
                          <img
                            height="30px"
                            width="30px"
                            src="../assets/images/phone.png"
                            alt=""
                          />
                          <h1 className="heading card-title">Communication</h1>
                          <p className="subheading card-paragraph">
                            {menu.communication}
                          </p>
                        </div>
                        <div className="timing-card">
                          <img
                            height="30px"
                            width="30px"
                            src="../assets/images/dollar.png"
                            alt=""
                          />
                          <h1 className="heading card-title">Billing</h1>
                          <p className="subheading card-paragraph">
                            {menu.billing}
                          </p>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        <Quotes></Quotes>
        <section className="why-us" id="why-us">
          <div className="container why-us-wrapper">
            <div className="row">
              <div className="col-lg-5 grid-container">
                {FAQs.length > 0 &&
                  FAQs.filter(
                    (faq) => faq.belongsTo.title === fetchTech.title
                  ).map((faq, index) => {
                    return <Accordion key={index} {...faq} />;
                  })}
              </div>
              <Form
                className="col-lg-6 inquiry-form"
                noValidate
                validated={validated}
              >
                <div className="info">
                  <div className="title">
                    <h2 className="heading">
                      Talk to us and get your project moving!
                    </h2>
                  </div>
                  <Form.Group className="mb-3" controlId="formBasicTech">
                    <Form.Select
                      aria-label="Select Technology"
                      required
                      name="tech"
                      id="tech"
                    >
                      <option>{tech}</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select a tech.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Control
                      required
                      placeholder="Full Name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      style={{
                        border: "none",
                        "border-bottom": "1px solid #686868",
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your name.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      required
                      placeholder="Email Address"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{
                        border: "none",
                        "border-bottom": "1px solid #686868",
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your email.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicBudget">
                    <Form.Control
                      required
                      placeholder="My Budget is.."
                      type="text"
                      value={budget}
                      onChange={(e) => setBudget(e.target.value)}
                      style={{
                        border: "none",
                        "border-bottom": "1px solid #686868",
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide your budget.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicMessage">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={message}
                      placeholder="My requirements are"
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 upload-btn-wrapper"
                    controlId="fileInput"
                  >
                    <button className="btn-upload-hiring">
                      Upload Attachments
                    </button>
                    <Form.Control
                      type="file"
                      name="file"
                      style={{ height: "auto" }}
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </Form.Group>
                  <Form.Label style={{ marginTop: "20px" }}>
                    Note: Please upload pdf,doc or docx files only!
                  </Form.Label>
                  <div className="content-bottom">
                    <button
                      className="btn-continue"
                      type="submit"
                      href="#"
                      onClick={handleSubmit}
                    >
                      Request a callback
                    </button>
                    <ToastContainer />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </section>
        <section className="hiring-process" style={{ marginTop: "0px" }}>
          {cmsData.length > 0 && (
            <div className=" container hiring-process-wrapper">
              <div className="content-header">
                <h1 className="heading">{cmsData[0].title}</h1>
                <p className="subheading">
                  {removeHTML(cmsData[0].description)}
                </p>
              </div>

              <div className="benefits">
                {cmsData
                  .filter((data) => data !== cmsData[0])
                  .map((data, index) => {
                    const numberValue = numbers[index].number;
                    return (
                      <div key={index} className="content-interview-process">
                        {/* <span
                          className="material-icons"
                          // style={{
                          //   color: "rgba(255, 255, 255, 0.23)",
                          //   borderRadius: 19,
                          // }}
                        >
                          <img
                            className="img-fluid"
                            src={getFileUrl(data.image)}
                            alt=""
                      
                            style={{ objectFit: "contain" }}
                          />
                    </span>*/}
                        <span className="numbers-interview">{numberValue}</span>
                        <h1 className="heading mt-2">{data.title}</h1>
                        <p className="subheading">
                          {removeHTML(data.description)}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </section>
        {/* {works.filter((work) => work.tags.includes(tech)).length > 0 && (
          <section className="work" id="work">
            <div className="container work-wrapper">
              <div className="content-center">
                <h1 className="heading">Works done using {fetchTech.title}</h1>
              </div>
              <div className="flex-container mt-3">
                {works
                  .filter((work) => work.tags.includes(fetchTech.title))
                  .map((work, index) => {
                    return <WorkSection key={index} {...work} />;
                  })}
              </div>
            </div>
          </section>
        )} */}
      </main>
    </>
  );
}

export default HireDeveloper;
