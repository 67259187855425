import { useEffect, useRef, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../LoadingSpinner";
import Services from "./services";
import getFileUrl from "../../utils/getFileURL";
import makeRequest from "../../utils/api";
import { removeHTML } from "../../utils/util";
import Quotes from "../Quotes/quotes";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import WorkSection from "../../components/work-section/work-section";
import { FiArrowRight, FiChevronRight } from "react-icons/fi";
import "../../css/navigation.min.css";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
import ResultPage from "./ResultPage";
import InquirySection from "./InquirySection";

function Home() {
  const [name, setName] = useState("");
  const [tech, setTech] = useState("");
  const [email, setEmail] = useState("");
  const [budget, setBudget] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [validated, setValidated] = useState(false);
  const [fetchTech, setFetchTech] = useState([]);
  const [fetchPartners, setFetchPartners] = useState([]);
  const [blogsData, setBlogsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [homeCMS, setHomeCMS] = useState([]);
  const [resultCMS, setResultCMS] = useState([]);
  const [workProcessCMS, setWorkProcessCMS] = useState([]);
  const [works, setWorks] = useState([]);
  const [cmsWorks, setCMSWorks] = useState([]);

  const [nameBorderColor, setNameBorderColor] = useState("#686868");
  const [emailBorderColor, setEmailBorderColor] = useState("#686868");
  const [budgetBorderColor, setBudgetBorderColor] = useState("#686868");

  SwiperCore.use([Autoplay, Pagination, Navigation]);

  const intopros = [
    {
      img: "../assets/images/images.png",
    },

    {
      img: "../assets/images/images.png",
    },
    {
      img: "../assets/images/images.png",
    },
    {
      img: "../assets/images/images.png",
    },
  ];

  const swiperBreakpoints = {
    320: {
      slidesPerView: 2,
      spaceBetween: 5,
    },

    567: {
      slidesPerView: 3,
      spaceBetween: 5,
    },

    640: {
      slidesPerView: 3,
      spaceBetween: 5,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 5,
    },
    1024: {
      slidesPerView: 6,
      spaceBetween: 5,
    },

    1199: {
      slidesPerView: 7,
      spaceBetween: 5,
    },
  };

  // const increaseNumber = () => {
  //   setResultCMS((prevResultCMS) => {
  //     const updatedResultCMS = [...prevResultCMS];
  //     updatedResultCMS.forEach((result) => {
  //       result.title = result.title + 1;
  //     });
  //     return updatedResultCMS;
  //   });
  // };

  // Automatically increase number every second
  // useEffect(() => {
  //   const intervalId = setInterval(increaseNumber, 1000);
  //   return () => clearInterval(intervalId);
  // }, []);

  const fetchCMS = async () => {
    setIsLoading(true);
    const res = await makeRequest(`/cms/homepage`);
    if (res) {
      setHomeCMS(res.data?.data.childPages);
      setIsLoading(false);
    }

    const resp = await makeRequest(`/cms/result-section`);
    if (resp) {
      setResultCMS(resp.data?.data.childPages);
    }

    const response = await makeRequest(`/cms/working-process`);
    if (response) {
      setWorkProcessCMS(response.data?.data.childPages);
    }
  };

  const fetchWorkCMS = async () => {
    const res = await makeRequest(`/cms/works`);
    if (res) {
      setCMSWorks(res.data?.data?.childPages[0]);
    }
  };

  const fetchWorks = async () => {
    const res = await makeRequest(`/works?take=8`);
    if (res) {
      setWorks(res?.data?.data);
    }
  };

  useEffect(() => {
    fetchCMS();
    fetchWorks();
    fetchWorkCMS();
  }, []);

  return (
    <>
      <Helmet>
        <title>Intopros Technologies Pvt Ltd</title>
        <meta
          name="description"
          content={homeCMS?.metaDescription ?? "Intopros Technologies Pvt Ltd"}
        />
      </Helmet>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <main>
          <section className="home" id="home">
            <div className="container home-wrapper">
              <div className="col-lg-12 content-center">
                <div className="heading d-flex justify-content-center">
                  <h1 className="col-lg-12">
                    {homeCMS.length > 0 && homeCMS[0].title}
                  </h1>
                </div>
                <div className="subheading">
                  <p>
                    We are a swarm network of certified, enthusiastic and
                    innovative minds focussing on bringing changes to the world
                    we live in.
                    {/* {homeCMS.length > 0 && removeHTML(homeCMS[0].description)} */}
                  </p>
                </div>
                <div className="btn-get-started">
                  <Link to="/contact-us">
                    <button className="btn">Get Started</button>
                  </Link>
                </div>
              </div>
              {/* <div className="partners d-flex ">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  pagination={{
                    el: ".swiper-pagination",
                    clickable: true,
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },

                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 50,
                    },
                  }}
                >
                  {intopros.map((item, i) => (
                    <SwiperSlide key={i}>
                      <img
                        src={item.img}
                        alt="images"
                        className="intopros-img"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                {/* <Swiper
                  modules={[Navigation, Pagination]}
                  slidesPerView={5}
                  navigation
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                >
                  {fetchPartners.length > 0 &&
                    fetchPartners.map((partner, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <img
                            className="img-fluid"
                            src={getFileUrl(partner.image)}
                            style={{
                              width: "50%",
                              aspectRatio: "3/2",
                              objectFit: "contain",
                              mixBlendMode: "color-burn",
                            }}
                            alt="Intopros Technologies"
                          />
                        </SwiperSlide>
                      );
                    })}
                </Swiper> 
              </div> */}
              <Quotes></Quotes>
            </div>
          </section>

          {resultCMS.length > 0 && (
            <section className="result" id="result">
              <div className="container-fluid result-wrapper result-wrapper">
                <div className="container">
                  <h1 className="heading mb-1">{resultCMS[0].title}</h1>
                  <p className="subheading col-md-6">
                    {removeHTML(resultCMS[0].description)}
                  </p>
                  <div className="content">
                    <ul className="menu  numbers-list">
                      {resultCMS
                        .filter((result) => result !== resultCMS[0])
                        .map((result, index) => {
                          return (
                            <li
                              key={index}
                              className="menu-list d-flex flex-column numbers-listItem"
                            >
                              <ResultPage result={result}></ResultPage>
                              {/*                               
                              <h1 className="mb-0 result-total">
                                {result.title}
                              </h1> */}
                              <span>{removeHTML(result.description)}</span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          )}

          <section className="our-works">
            <div className="container">
              <div className="row">
                <div className="header d-flex justify-content-center">
                  <div className="d-flex flex-column align-items-center">
                    <h1 className="heading">
                      {cmsWorks.title && cmsWorks.title}
                    </h1>
                    <p className="subheading" style={{ marginBottom: "48px" }}>
                      {cmsWorks.description && removeHTML(cmsWorks.description)}
                    </p>
                  </div>
                </div>

                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <div className="card-deck flex-container">
                    {works.map((work, index) => {
                      return (
                        <>
                          <div className="image-grid">
                            <WorkSection key={index} {...work} />
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </section>

          <Services />
          <section className="home-tools-and-technologies">
            <div className="container home-tools-and-technologies-wrapper">
              <div className="content-header">
                <h1 className="heading">{homeCMS[3] && homeCMS[3].title}</h1>
                <div className="subheading">
                  <p style={{ marginBottom: "0px" }}>
                    {homeCMS[3] && removeHTML(homeCMS[3].description)}
                  </p>
                </div>
              </div>
              <Container>
                <div className="menu-wrapper">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={1}
                    loop={true}
                    autoplay={{
                      delay: 2500,
                    }}
                    allowTouchMove={true}
                    pagination={{
                      el: ".swiper-pagination",
                      clickable: true,
                    }}
                    breakpoints={swiperBreakpoints}
                  >
                    {fetchTech.length > 0 &&
                      fetchTech.map((tech, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className="item">
                              <div
                                style={{
                                  border: "1px solid #E0E0E0",
                                  borderRadius: "4px",

                                  width: "100%",
                                }}
                              >
                                <li
                                  key={index}
                                  className="menu-item"
                                  style={{ padding: "1rem" }}
                                >
                                  <Link
                                    to={`services/${tech.slug}`}
                                    className="menu-link"
                                  >
                                    <img
                                      className="img-fluid"
                                      src={
                                        tech.image
                                          ? getFileUrl(tech.image)
                                          : "../logo.png"
                                      }
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "contain",
                                      }}
                                      alt="Intopros Technologies"
                                    />
                                  </Link>
                                </li>
                              </div>
                              <Link to={`services/${tech.slug}`}>
                                <div
                                  className="d-flex mt-1 align-items-center work-intopros"
                                  style={{ color: "#264C7D", gap: "0.3rem" }}
                                >
                                  <p style={{ marginBottom: "1px" }}>
                                    {tech.title}
                                  </p>
                                  <FiChevronRight size={20} />
                                </div>
                              </Link>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                </div>
              </Container>
            </div>
          </section>

          <InquirySection />
          {workProcessCMS.length > 0 && (
            <section className="working-process mt-0">
              <div className="container working-process-wrapper">
                <div className="content-heading d-flex flex-column align-items-center">
                  <h1 className="heading text-center">
                    {workProcessCMS[0].title}
                  </h1>
                  <span className="subtitle col-md-8">
                    {workProcessCMS.length > 0 &&
                      removeHTML(workProcessCMS[0].description)}
                  </span>
                </div>
                <div className="content-main">
                  {workProcessCMS
                    .filter((process) => process !== workProcessCMS[0])
                    .map((process, index) => {
                      return (
                        <div key={index} className="content">
                          <img
                            src={getFileUrl(process.image)}
                            alt=""
                            className="left-side-image"
                          />
                          <h1 className="heading">{process.title}</h1>
                          <p className="subheading">
                            {removeHTML(process.description)}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            </section>
          )}
          {homeCMS[4] && (
            <section className="who-we-are">
              <div className="container who-we-are-wrapper">
                <div className="col-md-6 col-sm-6 content-left">
                  <h1 className="heading">{homeCMS[4].title}</h1>
                  {/* <p className="subheading">
                    {removeHTML(homeCMS[4].description)}
                  </p> */}

                  <p className="subheading">
                    {(() => {
                      if (window.innerWidth > 991) {
                        return removeHTML(homeCMS[4].description);
                      } else {
                        return (
                          removeHTML(homeCMS[4].description).slice(0, 200) +
                          "..."
                        );
                      }
                    })()}
                  </p>
                  <div className="btn-wrapper gap-1 d-flex align-items-center">
                    <Link className="read-more" to="/about">
                      Read our full story
                    </Link>
                    <FiArrowRight color="#264C7D" className="who-we-are-icon" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 content-right">
                  <img
                    className="image img-fluid"
                    src={getFileUrl(homeCMS[4].image)}
                    alt=""
                  />
                </div>
              </div>
            </section>
          )}

          {blogsData.length > 0 && (
            <section className="blog" id="blog" style={{ paddingTop: "8rem" }}>
              <div className="container blog-wrapper">
                <div className="content-header">
                  <div className="content text-center d-flex flex-column justify-content-center">
                    <h1 className="heading">
                      {homeCMS[5] && homeCMS[5].title}
                    </h1>
                    <div className="subheading">
                      {/* <p>{homeCMS[5] && removeHTML(homeCMS[5].description)}</p> */}
                      <p>
                        The tools and technologies used here at intopros to
                        deliver you the best performing application.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 content-bottom">
                  <div className="card-deck cards">
                    {blogsData.map((blog, index) => {
                      return (
                        <div key={index} className="card">
                          <Link to={`/blogs/${blog.slug}`}>
                            <img
                              src={getFileUrl(blog.image)}
                              className="card-img-top"
                              alt={blog.title}
                            />
                            <div className="card-body">
                              <span>{blog.category}</span>
                              <h5 className="card-title">{blog.title}</h5>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="btn-wrapper gap-1 d-flex justify-content-center align-items-center mt-5">
                  <Link className="btn-read-more" to="/blogs">
                    Read more of our blogs
                  </Link>
                  <FiArrowRight color="#264C7D" className="blogs-icon" />
                </div>
              </div>
            </section>
          )}
        </main>
      )}
    </>
  );
}

export default Home;
