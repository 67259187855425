import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import makeRequest from '../../utils/api';
import getFileUrl from '../../utils/getFileURL';
import "./header.css"

const NavLogo = () => {
    const [siteSettings, setSiteSettings] = useState([]);

    const fetchData = async () => {
        const res = await makeRequest(`/site-settings`);
        if (res) {
            setSiteSettings(res?.data?.data);
        }
    }
    useEffect(() => {
       fetchData() 
    },[])
  return (
    <div>
      <Link className="navbar-brand image-brand-navbar" to="/">
        <img
          src={
            siteSettings.headerLogo
              ? getFileUrl(siteSettings.headerLogo)
              : "../assets/images/logo.png"
          }
          className="img-fluid"
          alt="Intopros"
        />
      </Link>
    </div>
  );
}

export default NavLogo