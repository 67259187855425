import { Link } from "react-router-dom";
import getFileUrl from "../../utils/getFileURL";
import "./work.css";
import { Col, Row } from "react-bootstrap";

function WorkSection(work) {
  return (
    <>
      <Link
        to={`/works/${work.slug}`}
        className="card justify-content-between gap-2 text-center"
      >
        <div className="image-card">
          <img
            src={work.logo ? getFileUrl(work.logo) : "/homePlaceholder.png"}
            className="card-img-top"
            // style={{
            //   aspectRatio: "3/2",
            // }}
            alt={work.title}
          />
          <div className="title-work-container">
            <h5 className="title mb-0">{work.title}</h5>
          </div>
        </div>

        {/* <h5 className="title mb-0" style={{ maxWidth: "15ch" }}>
          {work.title}
        </h5> */}
      </Link>
    </>
  );
}

export default WorkSection;
