import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileURL";
import LoadingSpinner from "../../LoadingSpinner";

function HeadlineBlog() {
  const [blogs, setBlogs] = useState([]);
  const [headline, setHeadline] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchHeadline = async () => {
    setIsLoading(true);
    const res = await makeRequest(`/blogs?take=0`);
    if (res) {
      const data = res.data?.data;
      setHeadline(data[0]);
      setBlogs(data.splice(0, 3));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHeadline();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="content-top p-1">
          <div className="col-lg-8 content-left">
            {headline && (
              <Link
                to={`/blogs/${headline.slug}`}
                className="card text-white"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={getFileUrl(headline.image)}
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-img-overlay">
                  <span className="text-uppercase">{headline.category}</span>
                  <p className="card-text">{headline.title}</p>
                </div>
              </Link>
            )}
          </div>
          <div className="col-lg-4">
            <div className="gap-2 content-right">
              {blogs.length > 0 &&
                blogs
                  .filter((blog) => blog.id !== headline.id)
                  .map((blog, index) => {
                    return (
                      <Link
                        to={`/blogs/${blog.slug}`}
                        key={index}
                        className="card text-white"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={getFileUrl(blog.image)}
                          className="card-img img-fluid"
                          alt="..."
                        />
                        <div className="card-img-overlay">
                          <span className="text-uppercase">
                            {blog.category}
                          </span>
                          <p className="card-text">{blog.title}</p>
                        </div>
                      </Link>
                    );
                  })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HeadlineBlog;
