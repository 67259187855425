import DOMPurify from "dompurify";
import parse from "html-react-parser";

export function removeHTML(str) {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || "";
}

export const ParseHTML = (htmlString) => {
  const cleanHtmlString = DOMPurify.sanitize(htmlString, {
    USE_PROFILES: { html: true },
  });
  const html = parse(cleanHtmlString);
  return html;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { removeHTML, ParseHTML };
