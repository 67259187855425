import { Component } from "react";
import { Route, Routes } from "react-router-dom";
import About from "./About/about";
import BlogDetail from "./Blog-Detail/blog-detail";
import Blogs from "./Blogs/blogs";
import Careers from "./Careers/careers";
import HireDeveloper from "./Hire-a-developer/hire-a-developer";
import Services from "./Services/services";
import ToolsAndTechnologies from "./Tools&Technologies/tools-and-technologies";
import WorkDetail from "./Works/work-detail";
import Works from "./Works/works";
import ContactUs from "../components/contact-us/contact-us";
import ServiceDetail from "./Services/serviceDetail";
import PageNotFound from "../components/NoDataFound";
import Home from "./Home/home";
import FrameworkDetail from "./Services/framework-detail";
import CareerDetail from "./Careers/career-detail";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";

class PageRoutes extends Component {
  render() {
    return (
      <>
        <Routes>
          <Route path="/" element={<Home></Home>} />
          <Route path="/about" element={<About></About>} />
          <Route path="/blogs" element={<Blogs></Blogs>} />
          <Route path="/blogs/:id" element={<BlogDetail></BlogDetail>} />
          <Route path="/works" element={<Works></Works>} />
          <Route path="/works/:id" element={<WorkDetail></WorkDetail>} />
          <Route
            path="/service-detail/:id"
            element={<ServiceDetail></ServiceDetail>}
          />
          <Route path="/services/:id" element={<Services></Services>} />
          <Route
            path="/hire-a-developer/:id"
            element={<HireDeveloper></HireDeveloper>}
          />
          <Route
            path="/tools-and-technologies"
            element={<ToolsAndTechnologies></ToolsAndTechnologies>}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy></PrivacyPolicy>} />
          <Route path="/careers" element={<Careers></Careers>} />
          <Route path="/careers/:id" element={<CareerDetail></CareerDetail>} />
          <Route
            path="/contact-us/:packageID/:id"
            element={<ContactUs></ContactUs>}
          />
          <Route path="/contact-us/:id" element={<ContactUs></ContactUs>} />
          <Route path="/contact-us" element={<ContactUs></ContactUs>} />
          <Route
            path="/frameworks/:id"
            element={<FrameworkDetail></FrameworkDetail>}
          />
          <Route path="*" element={<Home></Home>} />
        </Routes>
      </>
    );
  }
}

export default PageRoutes;
