import React, { useEffect, useRef, useState } from "react";
import { useSpring, animated } from "react-spring";

const ResultPage = ({ result }) => {
  const [animatedTitle, setAnimatedTitle] = useState(result?.title);
  const [scrollPosition, setScrollPosition] = useState(0);
  console.log("animatedTitle", animatedTitle);
  const [animationStarted, setAnimationStarted] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const number = parseInt(animatedTitle);

  //     if (!isNaN(number) && number > 1) {
  //       const newNumber = number - 1;
  //       // Update the animatedTitle state with the new number
  //       setAnimatedTitle(String(newNumber));
  //       // Set the background color change flag
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [animatedTitle]);

  // const { number } = useSpring({
  //   number: parseInt(animatedTitle) || 0, // Parse as number or default to 0
  //   from: { number: 0 }, // Animate from 0 to the new value
  //   config: { duration: 1000 },
  //   onRest: () => setAnimatedTitle(result?.title), // Update animatedTitle after animation completes
  // });

  //  useEffect(() => {
  //    const handleWheel = (event) => {
  //      const isScrollingDown = event.deltaY > 0;

  //      if (isScrollingDown) {
  //        const number = parseInt(animatedTitle);
  //        if (!isNaN(number) && number > 0) {
  //          const newNumber = number - 1;
  //          setAnimatedTitle(String(newNumber));
  //        }
  //      }
  //    };

  //    window.addEventListener("wheel", handleWheel);

  //    return () => {
  //      window.removeEventListener("wheel", handleWheel);
  //    };
  //  }, [animatedTitle]);

  //  const { number } = useSpring({
  //    number: parseInt(animatedTitle) || 0, // Parse as number or default to 0
  //    from: { number: 0 }, // Animate from 0 to the new value
  //    config: { duration: 1000 },
  //    onRest: () => setAnimatedTitle(result?.title), // Update animatedTitle after animation completes
  //  });

  useEffect(() => {
    if (!animationStarted) {
      // Start the animation when the component mounts
      setAnimationStarted(true);
    } else {
      // If the animation has already started, do nothing
      return;
    }

   
  },[])

  const { number } = useSpring({
    number: parseInt(animatedTitle) || 0,
    from: { number: 0 },
    config: { duration: 1000 },
    onRest: () => setAnimatedTitle(result?.title),
  });

  return (
    <ul>
      <li className="menu-list d-flex flex-column">
        <animated.h1 className="mb-0 result-total">
          {number.interpolate((val) => {
            const roundedVal = Math.floor(val);
            return roundedVal >= 100 ? `${roundedVal}+` : roundedVal;
          })}
        </animated.h1>
      </li>
    </ul>
  );
};

export default ResultPage;
