import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import makeRequest from "../../utils/api";
import { Helmet } from "react-helmet";
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";

function CareerDetail() {
  const { id } = useParams();
  const [career, setCareer] = useState([]);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [careerID, setCareerID] = useState("");
  const [CV, setCV] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchCareer = async () => {
      const res = await makeRequest(`/careers/${id}`);
      if (res) {
        setCareer(res?.data?.data);
        setCareerID(res?.data?.data.id);
      }
    };
    fetchCareer();
  }, [id]);

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    } else {
      const data = new FormData();
      data.append("name", name);
      data.append("careerID", careerID);
      data.append("email", email);
      data.append("coverLetter", message);
      data.append("file", CV);

      const res = await makeRequest(`/careers/applications`, {
        method: "POST",
        data: data,
      });
      if (res) {
        toast.success(res.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
    setValidated(true);
  };

  return (
    <>
      <Helmet>
        <title>{career?.title}</title>
        <meta
          name="description"
          content={
            career?.metaDescription ??
            `${career.title} at Intopros Technologies Pvt Ltd`
          }
        />
      </Helmet>
      <main>
        <section className="career-detail mt-0 mb-5">
          <div
            className="container career-detail-wrapper"
            style={{ paddingTop: "12rem" }}
          >
            <div className="grid-container">
              <div className="card" style={{ padding: "2.5rem" }}>
                <div className="heading mb-1">
                  <h1>{career.title}</h1>
                  <small className="tag">{career.category}</small>
                </div>
                <ul className="list-unstyled d-flex align-items-center flex-wrap tags">
                  {career._tags &&
                    career._tags.map((tag, index) => (
                      <li key={index}>
                        <span className="mr-6 mt-3 d-flex align-items-center">
                          {tag}
                        </span>
                      </li>
                    ))}
                </ul>
                <div className="subheading mt-3">
                  <p className="mb-2">
                    <strong>Job Description</strong>
                  </p>
                  <div
                    className="career-description"
                    style={{ fontSize: "16px" }}
                  >
                    <ReactQuill
                      value={career?.description}
                      readOnly={true}
                      theme="bubble"
                    />
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="modal-header">
                  <div className="modal-title h4">Email Apply</div>
                  <Link to={`/careers`}>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                    />
                  </Link>
                </div>
                <div className="form-body">
                  <Form noValidate validated={validated}>
                    <Form.Group className="mb-3 controlInput1">
                      <Form.Group controlId="NameInput">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter Your Name"
                          style={{ margin: "0" }}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your name.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="EmailInput">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="Enter Your Email"
                          style={{ margin: "0" }}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your email.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="CoverLetterInput">
                      <Form.Label>Cover Letter</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Cover Letter cannot be empty.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="fileInput">
                      <Form.Label>Upload CV *</Form.Label>
                      <Form.Control
                        required
                        type="file"
                        name="file"
                        style={{ height: "auto" }}
                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        onChange={(e) => setCV(e.target.files[0])}
                      />
                    </Form.Group>
                    <Form.Label>
                      {" "}
                      * Please upload pdf,doc or docx files only!
                    </Form.Label>
                  </Form>
                </div>
                <div className="modal-footer">
                  <Link to={`/careers`}>
                    <button type="button" className="btn btn-secondary">
                      Close
                    </button>
                  </Link>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Apply
                  </button>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default CareerDetail;
