import { useEffect, useState } from "react";
import makeRequest from "../../utils/api";
import { removeHTML } from "../../utils/util";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../LoadingSpinner";
import getFileUrl from "../../utils/getFileURL";
import { Helmet } from "react-helmet";
import "./careers.css";
import { Link } from "react-router-dom";

function Careers() {
  const [careers, setCareers] = useState([]);
  const [careersCMS, setCareersCMS] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchItems = async () => {
    setIsLoading(true);
    const res = await makeRequest(`/careers?take=0`);
    if (res) {
      setCareers(res.data?.data);
      setIsLoading(false);
    }
  };

  const fetchCMS = async () => {
    const res = await makeRequest(`/cms/careers-at-intopros`);
    if (res) {
      setCareersCMS(res.data?.data);
    }
  };

  useEffect(() => {
    fetchItems();
    fetchCMS();
  }, []);

  return (
    <>
      <Helmet>
        <title>Careers At Intopros Technologies Pvt Ltd</title>
        <meta
          name="description"
          content={
            careersCMS?.metaDescription ??
            "Careers At Intopros Technologies Pvt Ltd"
          }
        />
      </Helmet>
      <main>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <section className="careers mt-0 mb-5" id="career">
            <div
              className="container careers-wrapper"
              style={{ paddingTop: "12rem" }}
            >
              <div className="content-header">
                <h1 className="heading">{careersCMS.title}</h1>
                <p className="subheading">
                  {careersCMS.description && removeHTML(careersCMS.description)}
                </p>
              </div>

              <div className="grid-container">
                {careers.map((tech, index) => {
                  return (
                    <div key={index} className="card">
                      <img
                        className="logo"
                        src={getFileUrl(tech?.image) ?? "../logo.png"}
                        width={80}
                        height={80}
                        alt=""
                      />
                      <div className="technology-details">
                        <h1>{tech.title}</h1>
                        <div className="tags">{tech.category}</div>
                        <div className="btn-group mt-3">
                          <Link to={`/careers/${tech.slug}`}>
                            <button className="btn-more">Read More</button>
                          </Link>
                          <Link to={`/careers/${tech.slug}`}>
                            <button className=" apply-button">Apply</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        )}
      </main>
    </>
  );
}

export default Careers;
