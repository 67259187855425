import { BrowserRouter } from "react-router-dom";
import PageRoutes from "./pages/pageRoutes";
import Header from "./components/header/navbar";
import Footer from "./components/footer/footer";
import "./pages/style.css";
import "./pages/Hire-a-developer/hire-a-developer.css";
import "./pages/Tools&Technologies/tools-and-technologies.css";
import ScrollToTop from "./components/scrollRestoration/scrollRestoration";
import "react-quill/dist/quill.snow.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WhatsApp from "./components/whatsapp/WhatsApp";


function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Header></Header>
        <PageRoutes></PageRoutes>
        <Footer></Footer>
        <WhatsApp/>
      </BrowserRouter>
    </>
  );
}

export default App;
