import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import makeRequest from "../../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactUs() {
  const { packageID, id } = useParams();
  const [name, setName] = useState("");
  const [tech, setTech] = useState("");
  const [email, setEmail] = useState("");
  const [budget, setBudget] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [validated, setValidated] = useState(false);
  const [fetchTech, setFetchTech] = useState([]);

  const fetchData = async (id) => {
    const resp = await makeRequest(`/technologies/${id}`);
    if (resp) {
      setTech(resp.data?.data.title);
    }

    const response = await makeRequest(`/technologies?take=0`);
    if (response) {
      setFetchTech(response.data?.data);
    }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const handleTechChange = (e) => {
    setTech(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setValidated(true);

      if (validated === true) {
        const data = new FormData();
        data.append("name", name);
        data.append("email", email);
        data.append("budget", budget);
        data.append("tech", tech);
        data.append("message", message);
        data.append("file", file);
        data.append("packageID", packageID);

        if (!packageID) {
          const res = await makeRequest(`/contact-us`, {
            method: "POST",
            data: data,
          });
          if (res) {
            toast.success(res.data?.message);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        } else {
          const res = await makeRequest(`/technologies/application`, {
            method: "POST",
            data: data,
          });
          if (res) {
            toast.success(res.data?.message);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        }
      }
    }
  };

  return (
    <div className="container why-us-wrapper">
      <div
        className="row"
        style={{
          paddingTop: "12rem",
          justifyContent: "center",
          marginBottom: "5rem",
        }}
      >
        <Form
          className="col-lg-6 col-md-8 col-sm-10 inquiry-form"
          noValidate
          validated={validated}
        >
          <div className="info">
            <div className="title">
              <h2 className="heading">
                Talk to us and get your project moving!
              </h2>
            </div>
            {/* {id ? (
              <Form.Group className="mb-3" controlId="formBasicTech">
                <Form.Select required name="tech" id="tech">
                  <option value={tech}>{tech}</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a tech.
                </Form.Control.Feedback>
              </Form.Group>
            ) : (
              <Form.Group className="mb-3" controlId="formBasicTech">
                <Form.Select
                  required
                  name="tech"
                  id="tech"
                  onChange={handleTechChange}
                >
                  <option selected hidden>
                    Select Technology
                  </option>
                  {fetchTech.map((tech, index) => {
                    return (
                      <option key={index} value={tech.title}>
                        {tech.title}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a tech.
                </Form.Control.Feedback>
              </Form.Group>
            )} */}
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Control
                required
                placeholder="Full Name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{
                  border: "none",
                  "border-bottom": "1px solid #686868",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                required
                placeholder="Email Address"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  border: "none",
                  "border-bottom": "1px solid #686868",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your email.
              </Form.Control.Feedback>
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="formBasicBudget">
              <Form.Control
                required
                placeholder="My Budget is.."
                type="text"
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
                style={{
                  border: "none",
                  "border-bottom": "1px solid #686868",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please provide your budget.
              </Form.Control.Feedback>
            </Form.Group> */}
            <Form.Group className="mb-3" controlId="formBasicMessage">
              <Form.Control
                as="textarea"
                rows={3}
                value={message}
                placeholder="My requirements are"
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3 upload-btn-wrapper"
              controlId="fileInput"
            >
              <button class="btn-upload">Upload Attachments</button>
              <Form.Control
                type="file"
                name="file"
                style={{ height: "auto" }}
                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </Form.Group>
            <Form.Label>
              Note: Please upload pdf,doc or docx files only!
            </Form.Label>
            <div className="content-bottom">
              <button
                className="btn-continue"
                type="submit"
                href="#"
                onClick={handleSubmit}
              >
                Request a callback
              </button>
              <ToastContainer />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ContactUs;
