import React, { useEffect, useState } from "react";
import "./whatsapp.css";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import makeRequest from "../../utils/api";

const WhatsApp = () => {
  const [siteSettings, setSiteSettings] = useState([]);
 console.log("siteSettings;;siteSettings;;", siteSettings);
  
  const fetchData = async () => {
    const res = await makeRequest(`/site-settings`);
    if (res) {
      setSiteSettings(res?.data?.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

   if (!siteSettings || !siteSettings.phone) {
     return <div>Loading...</div>;
   }

  const formattedPhone = siteSettings.phone.replace(/\s+/g, "");
  
  return (
    <WhatsAppWidget
      phoneNo={formattedPhone}
      position="right"
      widgetWidth="300px"
      widgetWidthMobile="260px"
      autoOpen={true}
      autoOpenTimer={5000}
      messageBox={true}
      messageBoxTxt="Hi Team, is there any related service available ?"
      iconSize="40"
      iconColor="white"
      iconBgColor="#25D366"
      headerIcon="	https://api.intopros.com/uploads/2023-05/unauth-1683267877211-7c817033926f4a80bcf13ed24f2da4d2.png"
      headerIconColor="pink"
      headerTxtColor="black"
      headerBgColor="#25D366"
      headerTitle="Intopros"
      headerCaption="Online"
      bodyBgColor="#bbb"
      chatPersonName="Support"
      chatMessage={
        <>
          Hi there 👋 <br />
          <br /> How can I help you?
        </>
      }
      footerBgColor="#999"
      placeholder="Type a message.."
      btnBgColor="#25D366"
      btnTxt="Start Chat"
      btnTxtColor="black"
    />
  );
};

export default WhatsApp;
