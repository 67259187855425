import { useEffect, useState } from "react";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileURL";
import "./quotes.css";
import { removeHTML } from "../../utils/util";

function Quotes() {
  const [testimonials, setTestimonials] = useState([]);

  const fetchItems = async () => {
    const res = await makeRequest(`/testimonials?take=0`);
    if (res) {
      setTestimonials(res.data?.data);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      {testimonials.length > 0 && (
        <section className="daily-quote" id="daily-quote">
          <div className="container">
            <div
              id="carouselExampleControls"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <img src="../assets/images/left-arrow.png" alt="" />
              </button>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div className="daily-quote-wrapper">
                    <img
                      className="quotation"
                      src="../assets/images/quotation.png"
                      alt=""
                    />
                    <p className="subheading">
                      {removeHTML(testimonials[0].description)}
                    </p>
                    <div className="profile">
                      <img
                        src={
                          testimonials[0].image
                            ? getFileUrl(testimonials[0].image)
                            : "../logo.png"
                        }
                        style={{
                          height: "58px",
                          width: "58px",
                          objectFit: "cover",
                          filter: "grayscale(1)",
                          borderRadius: "50%",
                        }}
                        className="img-fluid"
                        alt=""
                      />
                      <div className="profile-details">
                        <span className="name">
                          {testimonials.length > 0 && testimonials[0].name}
                        </span>
                        <p className="position">
                          {testimonials.length > 0 &&
                            testimonials[0].designation}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {testimonials
                  .filter((item) => item.id !== testimonials[0].id)
                  .map((item, index) => {
                    return (
                      <div key={index} class="carousel-item">
                        <div className="daily-quote-wrapper">
                          <img
                            className="quotation"
                            src="../assets/images/quotation.png"
                            alt=""
                          />
                          <p className="subheading">
                            {removeHTML(item.description)}
                          </p>
                          <div className="profile">
                            <img
                              src={
                                item.image
                                  ? getFileUrl(item.image)
                                  : "../logo.png"
                              }
                              style={{
                                height: "58px",
                                width: "58px",
                                objectFit: "cover",
                                filter: "grayscale(1)",
                                borderRadius: "50%",
                              }}
                              alt=""
                            />
                            <div className="profile-details">
                              <span className="name">{item.name}</span>
                              <p className="position">{item.designation}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <img src="../assets/images/right-arrow.png" alt="right arrow" />
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Quotes;
