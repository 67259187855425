import { useEffect, useState } from "react";
import makeRequest from "../../utils/api";
import { removeHTML } from "../../utils/util";
import getFileUrl from "../../utils/getFileURL";
import { Link } from "react-router-dom";

function Services() {
  const [services, setServices] = useState([]);
  const [homeCMS, setHomeCMS] = useState([]);

  const fetchData = async () => {
    const res = await makeRequest(`/services?take=0`);
    if (res) {
      setServices(res.data?.data);
    }
  };

  const fetchCMS = async () => {
    const res = await makeRequest(`/cms/homepage`);
    if (res) {
      setHomeCMS(res.data?.data.childPages);
    }
  };

  useEffect(() => {
    fetchData();
    fetchCMS();
  }, []);

  return (
    <main>
      {services.length > 0 && (
        <section className="works" id="works">
          <div className="container works-wrapper py-4">
            <div className="content-header d-flex flex-column align-items-center">
              <h1 className="heading">{homeCMS[2] && homeCMS[2].title}</h1>
              <p className="subheading">
                {homeCMS[2] && removeHTML(homeCMS[2].description)}
              </p>
            </div>
            <div>
              {services.map((service, index) => {
                return (
                  <div key={index} className="row py-0">
                    <>
                      {/* Above 767px */}
                      <div className="d-none d-lg-block">
                        {index % 2 === 0 ? (
                          <div className="content-bottom">
                            <div className="row">
                              <div className="col-lg-6 header-section">
                                <div className="description">
                                  <h1>{service.title}</h1>
                                  <p className="col-10">
                                    {removeHTML(
                                      service.shortDescription
                                    ).substring(0, 250)}
                                    ...
                                  </p>
                                  <Link
                                    to={`/service-detail/${service.slug}`}
                                    className="btn-more"
                                  >
                                    See more
                                  </Link>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <img
                                  className="image img-fluid"
                                  src={
                                    service.image
                                      ? getFileUrl(service.image)
                                      : ""
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="content-center">
                            <div className="row">
                              <div className="col-lg-6">
                                <img
                                  className="image img-fluid"
                                  src={
                                    service.image
                                      ? getFileUrl(service.image)
                                      : ""
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="col-lg-6 header-section">
                                <div className="description">
                                  <h1>{service.title}</h1>
                                  <p className="col-10">
                                    {removeHTML(
                                      service.shortDescription
                                    ).substring(0, 150)}
                                    ...
                                  </p>
                                  <Link
                                    to={`/service-detail/${service.slug}`}
                                    className="btn-more"
                                  >
                                    See more
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {/* Below 767px */}
                      <div className="d-lg-none">
                        {index % 2 === 0 ? (
                          <div className="content-bottom">
                            <div className="col-md-6">
                              <img
                                className="image img-fluid"
                                src={
                                  service.image ? getFileUrl(service.image) : ""
                                }
                                alt=""
                              />
                            </div>
                            <div className="col-lg-6 header-section">
                              <div className="description">
                                <h1>{service.title}</h1>
                                <p className="col-12">
                                  {removeHTML(
                                    service.shortDescription
                                  ).substring(0, 250)}
                                  ...
                                </p>
                                <Link
                                  to={`/service-detail/${service.slug}`}
                                  className="btn-more"
                                >
                                  See more
                                </Link>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="content-center">
                            <div className="col-md-6 order-md-2">
                              <img
                                className="image img-fluid"
                                src={
                                  service.image ? getFileUrl(service.image) : ""
                                }
                                alt=""
                              />
                            </div>
                            <div className="col-lg-6 header-section">
                              <div className="description">
                                <h1>{service.title}</h1>
                                <p className="col-12">
                                  {removeHTML(
                                    service.shortDescription
                                  ).substring(0, 150)}
                                  ...
                                </p>
                                <Link
                                  to={`/service-detail/${service.slug}`}
                                  className="btn-more"
                                >
                                  See more
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </main>
  );
}

export default Services;
