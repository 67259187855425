import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import makeRequest from "../../utils/api";

const InquirySection = () => {
  const [nameBorderColor, setNameBorderColor] = useState("#686868");
  const [emailBorderColor, setEmailBorderColor] = useState("#686868");
  const [budgetBorderColor, setBudgetBorderColor] = useState("#686868");
  const [selectTechgnology, setSelectTechnology] = useState("#686868");
  const [selectTextArea, setSelectTextArea] = useState("#686868");
  const [fetchTech, setFetchTech] = useState([]);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [tech, setTech] = useState("");
  const [email, setEmail] = useState("");
  const [budget, setBudget] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [fetchPartners, setFetchPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [blogsData, setBlogsData] = useState([]);
  const [placeholder, setPlaceholder] = useState("My requirements are");
  const [isClicked, setIsClicked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleTextAreaClick = () => {
    setPlaceholder("");
    setIsClicked(true);
    setIsHovered(false);
    // Ensure hover state is reset when clicked
  };

  const handleTextAreaHover = () => {
    setIsHovered(true);
    setIsClicked(false); // Ensure click state is reset when hovered
  };

  const handleSelectClick = () => {
    setSelectTechnology("#0588A6");
  };

  const handleNameClick = () => {
    setNameBorderColor("#0588A6");
  };

  const handleEmailClick = () => {
    setEmailBorderColor("#0588A6");
  };

  const handleBudgetClick = () => {
    setBudgetBorderColor("#0588A6");
  };

  const handleNameHover = () => {
    setNameBorderColor("#0588A6");
  };

  const handleEmailHover = () => {
    setEmailBorderColor("#0588A6");
  };

  const handleBudgetHover = () => {
    setBudgetBorderColor("#0588A6");
  };

  const handleTechChange = (e) => {
    setTech(e.target.value);
  };

  const handleSelectHover = () => {
    setSelectTechnology("#0588A6");
  };
  const fetchData = async () => {
    setIsLoading(true);
    const res = await makeRequest(`/technologies`);
    if (res) {
      setFetchTech(res.data?.data);
      setIsLoading(false);
    }

    const params = new URLSearchParams();
    params.set("take", (6).toString());

    setIsLoading(true);
    const response = await makeRequest(`/blogs?${params.toString()}`);
    if (response) {
      setBlogsData(response.data?.data);
      setIsLoading(false);
    }

    setIsLoading(true);
    const resp = await makeRequest(`/our-client?take=0`);
    if (resp) {
      setFetchPartners(resp.data?.data);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setValidated(true);

      if (validated === true) {
        const data = new FormData();
        data.append("name", name);
        data.append("email", email);
        data.append("budget", budget);
        data.append("tech", tech);
        data.append("message", message);
        data.append("file", file);

        const res = await makeRequest(`/contact-us`, {
          method: "POST",
          data: data,
        });
        if (res) {
          toast.success(res.data?.message);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      }
    }
  };

  return (
    <div>
      <section className="inquiry" id="inquiry">
        <div className="container-fluid inquiry-wrapper">
          <div className="row">
            <div className="quote-section" style={{ position: "relative" }}>
              <div className="col-lg-12 quote-image">
                <img
                  className="col-sm-12"
                  src="../assets/images/quote-bg.png"
                  alt=""
                ></img>
                <div className="col-md-4 col-xs-7 quote-text ">
                  <p className="quote text-white">
                    To me, ideas are worth nothing unless executed. They are
                    just a multiplier. Execution is worth millions.
                  </p>
                  <small className="person text-white">Steve Jobs</small>
                </div>
              </div>
              <div className="col-md-6 col-sm-10 col-xs-4 form">
                <Form className="inquiry-form" noValidate validated={validated}>
                  <div className="info">
                    <div className="title">
                      <h2 className="heading">
                        Talk to us and get your project moving!
                      </h2>
                    </div>
                    {/* <Form.Group className="mb-3" controlId="formBasicTech">
                      <Form.Select
                        aria-label="Select Technology"
                        required
                        name="tech"
                        id="tech"
                        onChange={handleTechChange}
                        onClick={handleSelectClick}
                        onMouseEnter={handleSelectHover}
                        onMouseLeave={() => setSelectTechnology("lightgray")}
                        style={{
                          border: "none",
                          borderBottom: `1px solid ${
                            selectTechgnology === "#0588A6"
                              ? "#0588A6"
                              : "lightgray"
                          }`,
                        }}
                      >
                        <option selected hidden>
                          Select Technology
                        </option>
                        {fetchTech.map((tech, index) => {
                          return (
                            <option key={index} value={tech.title}>
                              {tech.title}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please select a tech.
                      </Form.Control.Feedback>
                    </Form.Group> */}
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Control
                        required
                        placeholder="Full Name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        style={{
                          border: "none",
                          borderBottom: `1px solid ${
                            nameBorderColor === "#0588A6"
                              ? "#0588A6"
                              : "lightgray"
                          }`,
                        }}
                        onClick={handleNameClick}
                        onMouseEnter={handleNameHover}
                        onMouseLeave={() => setNameBorderColor("lightgray")}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please enter your name.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        required
                        placeholder="Email Address"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                          border: "none",
                          borderBottom: `1px solid ${
                            emailBorderColor === "#0588A6"
                              ? "#0588A6"
                              : "lightgray"
                          }`,
                        }}
                        onClick={handleEmailClick}
                        onMouseEnter={handleEmailHover}
                        onMouseLeave={() => setEmailBorderColor("lightgray")}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter your email.
                      </Form.Control.Feedback>
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="formBasicBudget">
                      <Form.Control
                        required
                        placeholder="My Budget is.."
                        type="text"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                        onClick={handleBudgetClick}
                        onMouseEnter={handleBudgetHover}
                        onMouseLeave={() => setBudgetBorderColor("lightgray")}
                        style={{
                          border: "none",
                          borderBottom: `1px solid ${
                            budgetBorderColor === "#0588A6"
                              ? "#0588A6"
                              : "lightgray"
                          }`,
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide your budget.
                      </Form.Control.Feedback>
                    </Form.Group> */}
                    <Form.Group className="mb-3" controlId="formBasicMessage">
                      <Form.Control
                        placeholder={placeholder}
                        className="textarea"
                        type="text"
                        as="textarea"
                        rows={3}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        style={{
                          boxShadow:
                            isClicked || isHovered
                              ? "0 0 5px rgba(0, 0, 0, 0.3)"
                              : "none",
                        }}
                        onClick={handleTextAreaClick}
                        onMouseEnter={handleTextAreaHover}
                        onMouseLeave={() => setIsHovered(false)}
                      />
                    </Form.Group>

                    {/* <Form.Group
                      controlId="validationCustom01"
                      className="mb-4"
                      style={{ border: "2px solid red" }}
                    >
                      <Form.Control
                        className="custom-textarea"
                        as="textarea"
                        placeholder="Write your message here*"
                        rows={3}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        // style={{
                        //   boxShadow:
                        //     isClicked || isHovered
                        //       ? "0 0 5px rgba(0, 0, 0, 0.3)"
                        //       : "none",
                        // }}
                        style={{
                          border: "2px solid green",
                          margin: 0,
                          padding: "22px 18px",
                        }}
                        onClick={handleTextAreaClick}
                        onMouseEnter={handleTextAreaHover}
                        onMouseLeave={() => setIsHovered(false)}
                      />
                    </Form.Group> */}

                    <Form.Group
                      className="mb-3 upload-btn-wrapper"
                      controlId="fileInput"
                    >
                      <button class=" inquiry-btn">Upload Attachments</button>
                      <Form.Control
                        type="file"
                        name="file"
                        style={{ height: "auto" }}
                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                    </Form.Group>
                    <Form.Label style={{ marginTop: "30px" }}>
                      Note: Please upload pdf,doc or docx files only!
                    </Form.Label>
                    <div className="content-bottom">
                      <button
                        className="btn-continue"
                        type="submit"
                        href="#"
                        onClick={handleSubmit}
                      >
                        Request a callback
                      </button>
                      <ToastContainer />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default InquirySection;
