import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import Quotes from "../Quotes/quotes";
import makeRequest from "../../utils/api";
import { removeHTML } from "../../utils/util";
import getFileUrl from "../../utils/getFileURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Accordion from "../../components/accordion/faqAccordion";
import LoadingSpinner from "../../LoadingSpinner";
import { Helmet } from "react-helmet";
import WorkSection from "../../components/work-section/work-section";
import ResultPage from "../Home/ResultPage";

function Services() {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [tech, setTech] = useState("");
  const [email, setEmail] = useState("");
  const [budget, setBudget] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [validated, setValidated] = useState(false);
  const [techDetail, setTechDetail] = useState([]);
  const [frameworks, setFrameworks] = useState([]);
  const [FAQs, setFAQs] = useState([]);
  const [works, setWorks] = useState([]);
  const [cmsData, setCmsData] = useState([]);
  const [techInfo, setTechInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cmsSectors, setCmsSectors] = useState([]);

  const [resultCMS, setResultCMS] = useState([]);
  const [selectTextArea, setSelectTextArea] = useState("My requirements are");
  const [isClicked, setIsClicked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleTextAreaClick = () => {
    setSelectTextArea("");
    setIsClicked(true);
    setIsHovered(false);
    // Ensure hover state is reset when clicked
  };

  const handleTextAreaHover = () => {
    setIsHovered(true);
    setIsClicked(false); // Ensure click state is reset when hovered
  };

  const fetchData = async (id) => {
    setIsLoading(true);
    const res = await makeRequest(`/technologies/${id}`);
    if (res) {
      setTechDetail(res?.data?.data);
      setTech(res?.data?.data?.title);
      setIsLoading(false);
    }

    const response = await makeRequest(`/technologies/frameworks?take=0`);
    if (response) {
      setFrameworks(response.data?.data);
    }
  };

  const fetchTechInfo = async () => {
    const res = await makeRequest(`/technologies/info?take=0`);
    if (res) {
      setTechInfo(res.data?.data);
    }
  };

  const fetchWorks = async () => {
    const res = await makeRequest(`/works?take=0&status=all`);
    if (res) {
      setWorks(res.data?.data);
    }
  };

  const fetchFAQs = async () => {
    const res = await makeRequest(`/technologies/faq?take=0`);
    if (res) {
      setFAQs(res.data?.data);
    }
  };

  const fetchCMS = async () => {
    const res = await makeRequest(`/cms/result-section`);
    if (res) {
      setCmsData(res.data?.data.childPages);
    }

    const resp = await makeRequest(`/cms/sectors-we-work-in`);
    if (resp) {
      setCmsSectors(resp.data?.data.childPages);
    }
  };

  useEffect(() => {
    fetchData(id);
    fetchFAQs();
    fetchWorks();
    fetchCMS();
    fetchTechInfo();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setValidated(true);

      if (validated === true) {
        const data = new FormData();
        data.append("name", name);
        data.append("email", email);
        data.append("budget", budget);
        data.append("tech", tech);
        data.append("message", message);
        data.append("file", file);

        const res = await makeRequest(`/contact-us`, {
          method: "POST",
          data: data,
        });
        if (res) {
          toast.success(res.data?.message);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      }
    }
  };

  const fetchCMSresult = async () => {
    const resp = await makeRequest(`/cms/result-section`);
    if (resp) {
      setResultCMS(resp.data?.data.childPages);
    }
  };

  useEffect(() => {
    fetchCMSresult();
  }, []);

  return (
    <>
      <Helmet>
        <title>{techDetail?.title}</title>
        <meta
          name="description"
          content={`${techDetail?.title}-Intopros Technologies Pvt Ltd`}
        />
      </Helmet>
      <main>
        <section className="service-header">
          <div className="container-fluid  service-header-wrapper">
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <div className="heading">
                <div className="container">
                  <div className="heading-content">
                    <div className="my-5 heading-description">
                      <img
                        className="bg-white p-2 mt-4"
                        src={
                          techDetail.image
                            ? getFileUrl(techDetail.image)
                            : "logo.png"
                        }
                        width={80}
                        alt={techDetail.title}
                      />
                      <h1 className="mt-4 mb-0">{techDetail.title}</h1>
                      <p className="col-md-8 subheading">
                        {removeHTML(techDetail.shortDescription)}
                      </p>
                      <Link to={`/contact-us/${techDetail.slug}`}>
                        <button className="mb-4 btn-start">
                          Start your work with {techDetail.title}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {techInfo.filter((info) =>
              info.technologies.includes(techDetail.title)
            ).length > 0 && (
              <div className="container service-list">
                <div className="grid-container">
                  {techInfo
                    .filter((info) =>
                      info.technologies.includes(techDetail.title)
                    )
                    .map((info, index) => {
                      return (
                        <div key={index} className="content">
                          <img
                            src={
                              info.image
                                ? getFileUrl(info.image)
                                : "../assets/images/logo2.png"
                            }
                            className="img-fluid"
                            width={60}
                            style={{
                              objectFit: "contain",
                              filter: "grayscale(1)",
                            }}
                            alt={info.title}
                          />
                          <h1>{info.title}</h1>
                          <p className="description">
                            {removeHTML(info.description)}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="framework" style={{ "margin-top": "unset" }}>
          {frameworks.filter(
            (framework) => framework.belongsTo.id === techDetail.id
          ).length > 0 && (
            <div
              className="container framework-wrapper d-flex flex-column align-items-center"
              style={{ padding: "0 4rem" }}
            >
              <div className="content-header text-center flex-column">
                <h1
                  className="heading framework-heading"
                  style={{ fontWeight: "600" }}
                >
                  What we use
                </h1>
                <p className="subheading framework-section">
                  Some of the frameworks or tools we use for {techDetail.title}
                </p>
              </div>
              <div className="menu-wrapper">
                <ul className="menu">
                  {frameworks
                    .filter(
                      (framework) => framework.belongsTo.id === techDetail.id
                    )
                    .map((framework, index) => {
                      return (
                        <div className="item d-flex-column">
                          <div
                            style={{
                              border: "1px solid #E0E0E0",
                              borderRadius: "4px",
                            }}
                          >
                            <li
                              key={index}
                              className="menu-item text-center"
                              style={{ padding: "1rem" }}
                            >
                              <Link
                                to={`/frameworks/${framework.slug}`}
                                className="menu-link"
                              >
                                <img
                                  className="img-fluid"
                                  src={getFileUrl(framework.image)}
                                  style={{
                                    height: "100px",
                                    objectFit: "cover",
                                  }}
                                  alt=""
                                />
                              </Link>
                            </li>
                          </div>
                          <Link to={`/frameworks/${framework.slug}`}>
                            <h6>
                              {framework.title}{" "}
                              <i class="fa-light fa-greater-than"></i>
                            </h6>
                          </Link>
                        </div>
                      );
                    })}
                </ul>
              </div>
            </div>
          )}
        </section>
        {works.filter((work) => work.tags.includes(techDetail.title)).length >
          0 && (
          <section className="work" id="work">
            <div className="container work-wrapper">
              <div className="content-center">
                <h1 className="heading">Works done using {techDetail.title}</h1>
              </div>
              <div className="card-deck flex-container mt-5">
                {works
                  .filter((work) => work.tags.includes(techDetail.title))
                  .map((work, index) => {
                    return <WorkSection key={index} {...work} />;
                  })}
              </div>
            </div>
          </section>
        )}
        <section className="why-us" id="why-us">
          <div className="container why-us-wrapper">
            <div className="row">
              <div className="col-lg-5 grid-container">
                {FAQs.length > 0 &&
                  FAQs.filter((faq) => faq.belongsTo.id === techDetail.id).map(
                    (faq, index) => {
                      return <Accordion key={index} {...faq} />;
                    }
                  )}
              </div>
              <Form
                className="col-lg-6 inquiry-form"
                noValidate
                validated={validated}
              >
                <div className="info">
                  <div className="title">
                    <h2 className="heading">
                      Talk to us and get your project moving!
                    </h2>
                  </div>
                  <Form.Group className="mb-3" controlId="formBasicTech">
                    <Form.Select
                      aria-label="Select Technology"
                      required
                      name="tech"
                      id="tech"
                    >
                      <option value={tech}>{tech}</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select a tech.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Control
                      required
                      placeholder="Full Name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      style={{
                        border: "none",
                        "border-bottom": "1px solid #686868",
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your name.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      required
                      placeholder="Email Address"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{
                        border: "none",
                        "border-bottom": "1px solid #686868",
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your email.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicBudget">
                    <Form.Control
                      required
                      placeholder="My Budget is.."
                      type="text"
                      value={budget}
                      onChange={(e) => setBudget(e.target.value)}
                      style={{
                        border: "none",
                        "border-bottom": "1px solid #686868",
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide your budget.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicMessage">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={message}
                      placeholder={selectTextArea}
                      onChange={(e) => setMessage(e.target.value)}
                      style={{
                        boxShadow:
                          isClicked || isHovered
                            ? "0 0 5px rgba(0, 0, 0, 0.3)"
                            : "none",
                      }}
                      onClick={handleTextAreaClick}
                      onMouseEnter={handleTextAreaHover}
                      onMouseLeave={() => setIsHovered(false)}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 upload-btn-wrapper"
                    controlId="fileInput"
                  >
                    <button class="btn-upload">Upload Attachments</button>
                    <Form.Control
                      type="file"
                      name="file"
                      style={{ height: "auto" }}
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </Form.Group>
                  <Form.Label style={{ marginTop: "20px" }}>
                    Note: Please upload pdf,doc or docx files only!
                  </Form.Label>
                  <div className="content-bottom">
                    <button
                      className="btn-continue"
                      type="submit"
                      href="#"
                      onClick={handleSubmit}
                    >
                      Request a callback
                    </button>
                    <ToastContainer />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </section>
        <section className="hiring-developer">
          {cmsSectors.length > 0 && (
            <div className="container hiring-developer-wrapper">
              <div className="content-header">
                {/* <h1 className="heading">{cmsSectors[0].title}</h1> */}
                <h1 className="heading">Hire us based on the Industry</h1>
              </div>
              <div className="paragraph-container">
                <p className="hiring-paragraph">
                  The tools and technologies used here at intopros to deliver
                  you the best performing application.
                </p>
              </div>
              <div className="grid-container">
                <div className="benefits  gap-4">
                  {cmsSectors
                    .filter((data) => data !== cmsSectors[0])
                    .map((cms, index) => (
                      <div key={index} className="content-benifits">
                        <img
                          src={cms.image ? getFileUrl(cms.image) : ""}
                          className="img-fluid"
                          style={{ height: "60px" }}
                          alt=""
                        />
                        <h2 className="content-heading mt-3">{cms.title}</h2>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </section>
        <Quotes></Quotes>
        {/* <section className="service-result" id="service-result">
          {cmsData.length > 0 && (
            <div className="container service-result-wrapper">
              <h1 className="heading">{cmsData[0].title}</h1>
              <p className="subheading col-md-6">
                {removeHTML(cmsData[0].description)}
              </p>
              <div className="content">
                <ul className="menu">
                  {cmsData
                    .filter((data) => data !== cmsData[0])
                    .map((data, index) => {
                      return (
                        <li
                          key={index}
                          className="menu-list d-flex flex-column text-center"
                        >
                          <h1 className="mb-0">{data.title}</h1>
                          <span>{removeHTML(data.description)}</span>
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className="content-bottom">
                <Link to={`/contact-us`}>
                  <button className="btn-contact">Contact Us</button>
                </Link>
              </div>
            </div>
          )}
        </section> */}

        {resultCMS.length > 0 && (
          <section className="result" id="result">
            <div className="container-fluid result-wrapper-section">
              <div className="container">
                <h1 className="heading mb-1" style={{ fontWeight: "600" }}>
                  {resultCMS[0].title}
                </h1>
                <div className="d-flex justify-content-center">
                  <p
                    className="subheading col-lg-6  text-center "
                    style={{ fontWeight: "400" }}
                  >
                    {removeHTML(resultCMS[0].description)}
                  </p>
                </div>
                <div className="content">
                  <ul className="numbers-list">
                    {resultCMS
                      .filter((result) => result !== resultCMS[0])
                      .map((result, index) => {
                        return (
                          <li
                            key={index}
                            className="menu-list d-flex flex-column numbers-listItem"
                          >
                            <ResultPage result={result}></ResultPage>

                            <span>{removeHTML(result.description)}</span>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div className="contact-result">
                <button>Contact Us</button>
              </div>
            </div>
          </section>
        )}
      </main>
    </>
  );
}

export default Services;
