import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "../../LoadingSpinner";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileURL";
import { removeHTML } from "../../utils/util";
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import InquirySection from "../Home/InquirySection";
import ResultPage from "../Home/ResultPage";

function ServiceDetail() {
  const [serviceDetail, setServiceDetail] = useState([]);
  const [technologies, setTechnologies] = useState([]);
  const [cmsData, setCmsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [resultCMS, setResultCMS] = useState([]);
  const { id } = useParams();

  const fetchTechs = async () => {
    const res = await makeRequest(`/technologies?take=0`);
    if (res) {
      setTechnologies(res.data?.data);
    }
  };

  const fetchCMS = async () => {
    const res = await makeRequest(`/cms/result-section`);
    if (res) {
      setCmsData(res.data?.data.childPages);
    }
  };

  useEffect(() => {
    const fetchService = async () => {
      setIsLoading(true);
      const res = await makeRequest(`/services/${id}`);
      if (res) {
        setServiceDetail(res.data?.data);
        setIsLoading(false);
      }
    };
    fetchService();
    fetchTechs();
    fetchCMS();
  }, [id]);

  const fetchCMSresult = async () => {
    const resp = await makeRequest(`/cms/result-section`);
    if (resp) {
      setResultCMS(resp.data?.data.childPages);
    }
  };

  useEffect(() => {
    fetchCMSresult();
  }, []);

  return (
    <>
      <Helmet>
        <title>{serviceDetail?.title}</title>
        <meta
          name="description"
          content={`${serviceDetail?.title}-Intopros Technologies Pvt Ltd`}
        />
      </Helmet>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <main>
          <section className="tools-and-technologies">
            <div className="container-fluid px-0 tools-and-technologies-wrapper">
              <div className="header-section">
                <div className="heading-content text-center">
                  <h1 className="heading">{serviceDetail.title}</h1>
                  <div className="header-technologies">
                    <p className="subheading">
                      {removeHTML(serviceDetail.shortDescription)}
                    </p>
                  </div>

                  <Link to={`/works`} state={serviceDetail.title}>
                    <button className="btn-view mt-3">
                      View Related Works
                    </button>
                  </Link>
                </div>
              </div>
              <p className="container description mt-4">
                {removeHTML(serviceDetail?.description)}
              </p>

              {technologies.filter(
                (tech) =>
                  serviceDetail.tags && serviceDetail.tags.includes(tech.title)
              ).length > 0 && (
                <div className="container mt-5">
                  <h1 className="heading">Tools and Technologies</h1>
                  <div className="grid-container">
                    {technologies
                      .filter(
                        (tech) =>
                          serviceDetail.tags &&
                          serviceDetail.tags.includes(tech.title)
                      )
                      .map((tech, index) => {
                        return (
                          <div key={index} className="card">
                            <img
                              className="logo"
                              src={getFileUrl(tech.image)}
                              alt=""
                            />
                            <div className="technology-details">
                              <h1>{tech.title}</h1>
                              <p className="subheading">
                                {removeHTML(tech.shortDescription)}
                              </p>
                              <div className="btn-group">
                                <Link to={`/services/${tech.slug}`}>
                                  <button className="btn-more">
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          </section>

          <InquirySection />
          {/* <section className="service-result" id="service-result">
            {cmsData.length > 1 && (
              <div className="container service-result-wrapper">
                <h1 className="heading">{cmsData[0].title}</h1>
                <p className="subheading col-md-6">
                  {removeHTML(cmsData[0].description)}
                </p>
                <div className="content">
                  <ul className="menu">
                    {cmsData
                      .filter((data) => data !== cmsData[0])
                      .map((data, index) => {
                        return (
                          <li
                            key={index}
                            className="menu-list d-flex flex-column align-items-center"
                          >
                            <h1 className="mb-0">{data.title}</h1>
                            <span>{removeHTML(data.description)}</span>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="content-bottom">
                  <Link to={`/contact-us`}>
                    <button className="btn-contact">Contact Us</button>
                  </Link>
                </div>
              </div>
            )}
          </section> */}

          {resultCMS.length > 0 && (
            <section className="result" id="result">
              <div className="container-fluid result-wrapper-section">
                <div className="container">
                  <h1 className="heading mb-1" style={{ fontWeight: "600" }}>
                    {resultCMS[0].title}
                  </h1>
                  <div className="d-flex justify-content-center">
                    <p
                      className="subheading col-lg-6  text-center "
                      style={{ fontWeight: "400" }}
                    >
                      {removeHTML(resultCMS[0].description)}
                    </p>
                  </div>
                  <div className="content">
                    <ul className="numbers-list">
                      {resultCMS
                        .filter((result) => result !== resultCMS[0])
                        .map((result, index) => {
                          return (
                            <li
                              key={index}
                              className="menu-list d-flex flex-column numbers-listItem"
                            >
                              <ResultPage result={result}></ResultPage>

                              <span>{removeHTML(result.description)}</span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className="contact-result">
                  <button>Contact Us</button>
                </div>
              </div>
            </section>
          )}
        </main>
      )}
    </>
  );
}

export default ServiceDetail;
