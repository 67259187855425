import React from "react";
import "./spinner.css";
import { GridLoader } from "react-spinners";

export default function LoadingSpinner() {
  return(
    <div className="spinner-container">
      <img
        src="../assets/images/loader.gif"
        size={10}
        aria-label="Loading Spinner"
        data-testid="loader"
        color="#0588A6"
        style={{ width: "350px", height: "350px" }}
      />
    </div>
  );
}
