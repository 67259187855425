import "../Blog-Detail/blog-detail.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileURL";
import ReactQuill from "react-quill";
import { Helmet } from "react-helmet";

function BlogDetail() {
  const { id } = useParams();
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    const fetchBlog = async () => {
      const res = await makeRequest(`/blogs/${id}`);
      if (res) {
        setBlog(res?.data?.data);
      }
    };
    fetchBlog();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>{blog?.title}</title>
        <meta
          name="description"
          content={blog?.metaDescription ?? "Intopros Technologies Pvt Ltd"}
        />
      </Helmet>
      <main>
        <section className="blog-detail">
          <div className="container blog-detail-wrapper">
            <div className="content-blog">
              <div className="heading-blog">
                <h1>{blog.title}</h1>
              </div>
              <div className="blog-image">
                <img
                  src={blog.image ? getFileUrl(blog.image) : ""}
                  className="img-responsive"
                  alt={blog.title}
                />
              </div>
              <div className="subheading">
                <ReactQuill
                  value={blog?.description}
                  readOnly={true}
                  theme="bubble"
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default BlogDetail;
