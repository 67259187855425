import "../Blogs/blogs.css";
import { useEffect, useState } from "react";
import HeadlineBlog from "./headlineBlog";
import makeRequest from "../../utils/api";
import { removeHTML } from "../../utils/util";
import getFileUrl from "../../utils/getFileURL";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [blogsCMS, setBlogsCMS] = useState([]);

  const fetchBlogs = async () => {
    const res = await makeRequest(`/blogs?take=0`);
    if (res) {
      const data = res.data?.data;
      const filter = data.splice(3);
      setBlogs(filter);
    }
  };

  const fetchCMS = async () => {
    const res = await makeRequest(`/cms/blogs`);
    if (res) {
      setBlogsCMS(res.data?.data.childPages);
    }
  };

  useEffect(() => {
    fetchBlogs();
    fetchCMS();
  }, []);

  return (
    <>
      <Helmet>
        <title>Intopros Technologies Blogs</title>
        <meta name="description" content={blogsCMS.metaDescription} />
      </Helmet>
      <main>
        <section className="blog">
          <div className="container blog-wrapper">
            <div className="content-header">
              <div className="content text-center d-flex flex-column align-items-center">
                <h1 className="heading">
                  {blogsCMS.length > 0 && blogsCMS[0].title}
                </h1>
                <div className="col-8 subheading blog-paragraph">
                  <p>
                    {blogsCMS.length > 0 && removeHTML(blogsCMS[0].description)}
                  </p>
                </div>
              </div>
            </div>
            <HeadlineBlog></HeadlineBlog>
            <div className="content-bottom mt-3">
              <div className="card-deck cards">
                {blogs.length > 0 &&
                  blogs.map((blog, index) => {
                    return (
                      <Link
                        to={`/blogs/${blog.slug}`}
                        key={index}
                        className="card"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={blog.image ? getFileUrl(blog.image) : ""}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body">
                          <span className="text-uppercase">
                            {blog.category}
                          </span>
                          <h5 className="card-title">{blog.title}</h5>
                          <p className="card-text">
                            {removeHTML(blog.description).substring(0, 150)}
                            <small>...</small>
                          </p>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Blogs;
