import { useState } from "react";
import ReactQuill from "react-quill";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

function Accordion({ question, answer }) {
  const [show, setShow] = useState(false);

  return (
    <div className="faq">
      <div
        className="question"
        onClick={(e) => setShow(!show)}
        style={{ cursor: "pointer" }}
      >
        <h3>{question}</h3>
        <div>
          {show ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />}
        </div>
      </div>
      <div className="answer">
        {show && <ReactQuill value={answer} readOnly={true} theme="bubble" />}
      </div>
    </div>
  );
}

export default Accordion;
